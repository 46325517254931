import { CustomerDetailContext } from 'providers/customer-detail'
import { useContext } from 'react'
import styled from 'styled-components'
import InfoSummary from 'views/onboarding/header/info-summary'
import UploadDocuments from 'views/actions/upload-documents'
import { CUSTOMER_STATES } from 'utils/schemas/customer'
import SendExternalLink from './external-link'
import RateProposal from './rate-proposal'
import CreditAnalysis from './credit-analysis'
import UnderwritingResult from './underwriting-result'
import CustomerApproval from './customer-approval'

const ActionByState = {
  cust_prospectCreated: SendExternalLink,
  cust_onBoarding: UploadDocuments,
  cust_inOperationsReview: UploadDocuments,
  cust_operationsAnalysis: UploadDocuments,
  cust_rateProposal: RateProposal,
  cust_inLegalReview: UploadDocuments,
  cust_inUnderwriterReview: UploadDocuments,
  cust_underwritingAnalysis: CreditAnalysis,
  cust_underwritingResult: UnderwritingResult,
  cust_customerApproval: CustomerApproval,
  cust_uploadContract: UploadDocuments,
  cust_customerCreated: UploadDocuments
}

const Actions = () => {
  const { workflowStatus } = useContext(CustomerDetailContext)
  const customerStatus = workflowStatus.currentState ?? workflowStatus.state

  const Action = Boolean(customerStatus) ? (
    ActionByState[customerStatus]
  ) : (
    <h1>Action not found</h1>
  )

  const stateConfig = CUSTOMER_STATES[customerStatus]

  return (
    <StyledAction>
      <div className="info">
        <InfoSummary currentRoute={['Action', stateConfig.actionLabel]} />
      </div>
      <Action
        currentSection={stateConfig?.stage}
        showAcceptanceCheck={stateConfig?.isReviewDocuments}
      />
    </StyledAction>
  )
}

const StyledAction = styled.div`
  .info {
    padding: 2rem 2rem 0 2rem;
  }
`

export default Actions
