import { format } from 'date-fns'
import { es } from 'date-fns/locale'

/**
 * function to display the date in a format.
 * If the date is undefined, it returns today's date
 * @param {Date | string} [date] - date to format
 * @param {string} [finalFormat] - format to use
 * @returns
 */
export const dateFormatFromIso = (date, finalFormat) => {
  date ??= new Date()
  finalFormat ??= 'dd/MM/yyyy hh:mm a'
  return format(new Date(date), finalFormat, { locale: es })
}
