import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import CustomerForm from 'containers/on-boarding/customer-form'
import { AlertContext } from 'providers/alert'
import { LATERAL_MENU_OPTIONS, MenuBarContext } from 'providers/menu-bar'
import { useContext, useEffect, useState } from 'react'
import { createCustomer, getProjectWorkflows } from 'services/workflow'
import styled from 'styled-components'
import { formatCreateOwner } from 'utils/functions/format-customer-form'
import { CUSTOMER_FORM_SCHEMAS } from 'utils/schemas/customer'
import { validateForm, validateFormInputNotEmpty } from 'utils/validators'

const initialCustomerForm = {
  wfStage: 'OnBoarding',
  wfState: 'cust_prospectCreated'
}

const CreateOnboarding = () => {
  const [formValues, setFormValues] = useState(initialCustomerForm)
  const [workflow, setWorkflow] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [formErrors, setFormErrors] = useState({})
  const [showErrors, setShowErrors] = useState(false)
  const { setSelectedOption } = useContext(MenuBarContext)
  const { setAlert } = useContext(AlertContext)

  const sendValidation = async () => {
    const schemas = [
      ...CUSTOMER_FORM_SCHEMAS.FORM_INFORMATION,
      ...CUSTOMER_FORM_SCHEMAS.FORM_FINANCIAL_INFORMATION,
      ...CUSTOMER_FORM_SCHEMAS.FORM_LOAN_REQUEST,
      ...CUSTOMER_FORM_SCHEMAS.FORM_CONTACT
    ]
    /* Identify form errors by fields validating */
    const formErrors = validateFormInputNotEmpty(schemas, formValues)
    setFormErrors(formErrors)

    const ownerErrors =
      formValues?.owners?.map((itm) =>
        validateForm(CUSTOMER_FORM_SCHEMAS.FORM_ADD_OWNER, itm)
      ) ?? []
    /* Check if exist form errors */
    const formValid =
      isEmpty(formErrors) && !ownerErrors.find((itm) => !isEmpty(itm))

    return formValid
  }

  const onHandleSubmit = async () => {
    setShowErrors(true)
    Object.entries(formValues).forEach(([key, value]) => {
      if (typeof value === 'string') formValues[key] = value.trim()
      if (value === '') formValues[key] = null
    })
    try {
      const formValid = await sendValidation()
      /* Format form to service POST body */
      const formToCreation = formatFormToRequest(formValues)
      if (formValid) {
        setIsLoadingSubmit(true)
        await createCustomer(workflow?.workflowId, {
          ...formToCreation
        })
        setIsLoadingSubmit(false)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD)
      } else {
        throw new Error('Form Not Valid')
      }
    } catch (err) {
      console.error(err)
      const message = err?.message
        ? `with the following message: ${err?.message}`
        : ''
      setAlert({
        title: 'Error',
        label: `An error ocurred ${message}. Please check that you have filled out the form correctly.`,
        type: 'error'
      })
      setIsLoadingSubmit(false)
    }
  }

  useEffect(() => {
    const fetchWorkflow = async () => {
      const { data } = await getProjectWorkflows()
      const { versions } = data?.body
      const lastWorkflow = versions.pop()
      setWorkflow(lastWorkflow)
      setIsLoading(false)
    }
    fetchWorkflow()
  }, [])

  useEffect(() => {
    if (showErrors) {
      sendValidation()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues])

  return (
    <OnboardingStyled>
      {isLoading && <LoadingAnimation />}
      {!isLoading && (
        <>
          <h3>Prospect Creation</h3>
          <CustomerForm
            setFormValues={setFormValues}
            formValues={formValues}
            formErrors={formErrors}
            isValidating={showErrors}
          />
          <div className="action-group">
            <Button
              buttonType="grayButton"
              onClick={() => setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD)}
            >
              Cancel
            </Button>
            <Button onClick={onHandleSubmit} disabled={isLoadingSubmit}>
              Create Prospect
            </Button>
          </div>
        </>
      )}
    </OnboardingStyled>
  )
}

/**
 *
 * @param {object} obj
 * @returns
 */
const isEmpty = (obj) => Object.keys(obj).length === 0

/**
 *
 * @param {object} formValues
 * @returns
 */
const formatFormToRequest = (formValues) => {
  Object.entries(formValues).forEach(([key, value]) => {
    if (typeof value === 'string') formValues[key] = value.trim()
  })
  return Object.entries(formValues).reduce((acc, [key, value]) => {
    const [newKey, group] = key.split(':')
    if (group) {
      return { ...acc, [group]: { ...acc[group], [newKey]: value } }
    }
    if (newKey === 'owners') {
      const newOwners = value?.map((itm) => formatCreateOwner(itm))
      value = newOwners
    }
    return { ...acc, [newKey]: value }
  }, {})
}

const OnboardingStyled = styled.div`
  padding: 2rem;

  .action-group {
    margin: 2rem 42px 0;

    button + button {
      margin-left: 28px;
    }
  }

  .error-message {
    color: red;
    font-weight: bold;
  }
`

export default CreateOnboarding
