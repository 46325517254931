import { Icon } from '@keoworld/gbl-ui-kit'
import Form from 'containers/form'
import styled from 'styled-components'
import {
  MAXIMUM_AMOUNT_PER_LINE_I2C,
  MINIMUM_AMOUNT_PER_LINE_I2C
} from 'utils/constants'
import { CurrencyFormatUSD } from 'utils/functions/form-data'
import { LINE_FORM } from 'utils/schemas/line'

/**
 *
 * @param {object} params
 * @param {object} params.formValues
 * @param {function} params.setFormValues
 * @returns
 */
const LineForm = ({
  title,
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
  isValidating
}) => {
  return (
    <LineFormStyled>
      <h3>{title ? title : 'Line Information'}</h3>
      <div className="cont-gray">
        <span className="icon">
          <Icon type="outlined" name="warning" />
        </span>
        <span className="card">
          <p>
            <strong>IMPORTANT</strong> <br />
            Please note that for the approval of lines the amounts established
            in the system are a {' '}
            <strong>
              <span className="text-uppercase"> minimum</span> of USD{' '}
              {CurrencyFormatUSD.format(MINIMUM_AMOUNT_PER_LINE_I2C)}
            </strong>
            {' '} and a {' '}
            <strong>
              <span className="text-uppercase"> maximum</span> of USD{' '}
              {CurrencyFormatUSD.format(MAXIMUM_AMOUNT_PER_LINE_I2C)}
            </strong>
            {' '} per line.
          </p>
        </span>
      </div>
      <section className="line-form">
        <Form
          schema={LINE_FORM}
          formValues={formValues}
          setFormValues={setFormValues}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          isValidating={isValidating}
        />
      </section>
    </LineFormStyled>
  )
}

const LineFormStyled = styled.div`
  padding: 1rem 2rem;
  margin: 0 0 1rem 0;
  .line-form {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    & > div {
      padding: 0 1rem;
      &:nth-child(3n + 1) {
        padding-left: 0;
      }
      &:nth-child(3n) {
        padding-right: 0;
      }
    }
  }
  .cont-gray {
    margin: 1rem 0;
    background-color: #efefef;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 11px;
    display: grid;
    grid-template-columns: 10% 90%;
    .card {
      margin: 1rem 1rem;
      padding-right: 6rem;
      p {
        margin: 0 0;
        font-weight: normal;
      }
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      color: orange;
      span {
        font-size: 3.5rem;
      }
    }
  }
  .text-uppercase {
    text-transform: uppercase;
  }
`

export default LineForm
