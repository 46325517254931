export const FILE_STAGES = {
  OnBoarding: 'OnBoarding',
  Operation: 'Operation',
  Underwriting: 'Underwriting',
  Legal: 'Legal'
  //Disbursement: "Disbursement",
  //Collection: "Collection"
}

export const FILE_STATES = {
  DRAFT: 'Draft',
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected'
}

export const FILE_FORM_SCHEMAS = {
  OnBoarding: [
    {
      label: 'Six most recent bank statements',
      name: 'bank_statements_doc',
      type: 'file',
      isRequired: true,
      component: 'file',
      documentType: [
        '.pdf',
        'image/*',
        '.doc',
        '.docx',
        '.xlsx',
        '.xls',
        '.csv'
      ],
      rows: 1,
      fileTypeId: 1
    },
    {
      label:
        'Driver’s license for any officer with 25% or more ownership',
      name: 'driver_license_doc',
      type: 'file',
      isRequired: true,
      component: 'file',
      documentType: [
        '.pdf',
        'image/*',
        '.doc',
        '.docx',
        '.xlsx',
        '.xls',
        '.csv'
      ],
      rows: 1,
      fileTypeId: 2
    },
    {
      label: 'Business voided check',
      name: 'voided_check_doc',
      type: 'file',
      isRequired: true,
      component: 'file',
      documentType: [
        '.pdf',
        'image/*',
        '.doc',
        '.docx',
        '.xlsx',
        '.xls',
        '.csv'
      ],
      rows: 1,
      fileTypeId: 3
    },
    {
      label: 'Lease agreement or three (3) rent payments',
      name: 'lease_rent_doc',
      type: 'file',
      isRequired: true,
      component: 'file',
      documentType: [
        '.pdf',
        'image/*',
        '.doc',
        '.docx',
        '.xlsx',
        '.xls',
        '.csv'
      ],
      rows: 1,
      fileTypeId: 4
    },
    {
      label: 'Business or professional license',
      name: 'professional_license_doc',
      type: 'file',
      isRequired: true,
      component: 'file',
      documentType: [
        '.pdf',
        'image/*',
        '.doc',
        '.docx',
        '.xlsx',
        '.xls',
        '.csv'
      ],
      rows: 1,
      fileTypeId: 5
    },
    {
      label:
        'Tax returns for the prior fiscal year (lines of credit up to $100k) or for the last 2 years (greater than $100k)',
      name: 'tax_return_doc',
      type: 'file',
      isRequired: true,
      component: 'file',
      documentType: [
        '.pdf',
        'image/*',
        '.doc',
        '.docx',
        '.xlsx',
        '.xls',
        '.csv'
      ],
      rows: 1,
      fileTypeId: 6
    },
    {
      label:
        'Financial statements for the prior fiscal year (lines of credit up to $100k) or for the last 2 fiscal years (greater than $100k)',
      name: 'profit_loss_doc',
      type: 'file',
      isRequired: true,
      component: 'file',
      documentType: [
        '.pdf',
        'image/*',
        '.doc',
        '.docx',
        '.xlsx',
        '.xls',
        '.csv'
      ],
      rows: 1,
      fileTypeId: 7
    },
    {
      label:
        'Operating agreements and/or corporate resolution letters',
      name: 'balance_sheet_doc',
      type: 'file',
      isRequired: true,
      component: 'file',
      documentType: [
        '.pdf',
        'image/*',
        '.doc',
        '.docx',
        '.xlsx',
        '.xls',
        '.csv'
      ],
      rows: 1,
      fileTypeId: 8
    }
  ],
  Operation: [
    {
      label: 'Articles of Incorporation',
      name: 'articles_of_incorporation',
      type: 'file',
      isRequired: true,
      component: 'file',
      documentType: [
        '.pdf',
        'image/*',
        '.doc',
        '.docx',
        '.xlsx',
        '.xls',
        '.csv'
      ],
      rows: 1,
      fileTypeId: 10
    },
    {
      label: 'Background Check',
      name: 'background_check',
      type: 'file',
      isRequired: true,
      component: 'file',
      documentType: [
        '.pdf',
        'image/*',
        '.doc',
        '.docx',
        '.xlsx',
        '.xls',
        '.csv'
      ],
      rows: 1,
      fileTypeId: 11
    },
    {
      label: 'Credit Report',
      name: 'credit_report',
      type: 'file',
      isRequired: true,
      component: 'file',
      documentType: [
        '.pdf',
        'image/*',
        '.doc',
        '.docx',
        '.xlsx',
        '.xls',
        '.csv'
      ],
      rows: 1,
      fileTypeId: 12
    },
    {
      label: 'SOS Report',
      name: 'sos_report',
      type: 'file',
      isRequired: true,
      component: 'file',
      documentType: [
        '.pdf',
        'image/*',
        '.doc',
        '.docx',
        '.xlsx',
        '.xls',
        '.csv'
      ],
      rows: 1,
      fileTypeId: 13
    }
  ],
  Underwriting: [
    {
      label: 'BluCognition Evidence',
      name: 'bluecognition_evidence',
      type: 'file',
      isRequired: true,
      component: 'file',
      documentType: [
        '.pdf',
        'image/*',
        '.doc',
        '.docx',
        '.xlsx',
        '.xls',
        '.csv'
      ],
      rows: 1,
      fileTypeId: 14
    },
    {
      label: 'Risk & Assessment Report',
      name: 'risk_and_assessment',
      type: 'file',
      isRequired: true,
      component: 'file',
      documentType: [
        '.pdf',
        'image/*',
        '.doc',
        '.docx',
        '.xlsx',
        '.xls',
        '.csv'
      ],
      rows: 1,
      fileTypeId: 15
    }
  ],
  Legal: [
    {
      label: 'BNPL Agreement',
      name: 'bnlp_agreement',
      type: 'file',
      isRequired: true,
      component: 'file',
      documentType: [
        '.pdf',
        'image/*',
        '.doc',
        '.docx',
        '.xlsx',
        '.xls',
        '.csv'
      ],
      rows: 1,
      fileTypeId: 16
    },
    {
      label: 'Personal Guarantee',
      name: 'personal_guarantee',
      type: 'file',
      isRequired: false,
      component: 'file',
      documentType: [
        '.pdf',
        'image/*',
        '.doc',
        '.docx',
        '.xlsx',
        '.xls',
        '.csv'
      ],
      rows: 1,
      fileTypeId: 17
    },
    {
      label: 'Co-Borrower Addendum',
      name: 'coborrower_addendum',
      type: 'file',
      isRequired: false,
      component: 'file',
      documentType: ['.pdf', 'image/*'],
      rows: 1,
      fileTypeId: 18
    }
  ],
  additional: {
    label: 'Additional',
    name: 'additional',
    type: 'file',
    isRequired: false,
    component: 'file',
    documentType: ['.pdf', 'image/*', '.doc', '.docx', '.xlsx', '.xls', '.csv'],
    rows: 1,
    fileTypeId: 9
  }
}
