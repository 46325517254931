import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { GRACE_RATE } from 'utils/constants'
import { CurrencyFormatUSD } from 'utils/functions/form-data'
import { convertObjectValuesToString } from 'utils/functions/object-values-toString'

const LineCard = ({ title = 'Line', line }) => {
  const [lineValue, setLineValue] = useState({})

  const verifyNumber = (field, name) => {
    if (!field) return `Type in the field ${name}`
    const fieldNumber = parseInt(field)
    if (isNaN(fieldNumber)) return `Type a number in ${name}`
    if (fieldNumber === 0) return 'Does not apply'
    return undefined
  }

  useEffect(() => {
    const loadLine = async () => {
      const objectFormated = await convertObjectValuesToString(line)
      setLineValue(objectFormated)
    }
    loadLine()
  }, [line])

  return (
    <CreditAnalysisStyled>
      <div className='margin-2'>
        <h3>{title}</h3>
        <div className='cont-gray'>
          <section className='center-items'>
            <h4>Business Proposal (Customer)</h4>
            <p className='amount'>Amount</p>
            <p className='cash'>
              <span className='currency'>USD </span>
              {CurrencyFormatUSD.format(lineValue?.amount)}
            </p>
          </section>
          <section className='card'>
            <p>Payment Deadlines</p>
            <div className='col-2'>
              <span className='weight-600'>Grace Period</span>
              <span>
                {verifyNumber(lineValue.cashbackPeriod, 'Grace Period') ??
                  `1 - ${lineValue.cashbackPeriod} days`}
              </span>
              <span className='weight-600'>Term A</span>
              <span>
                {verifyNumber(lineValue?.termA, 'Term A') ??
                  `${parseInt(lineValue?.cashbackPeriod) + 1} - ${
                    lineValue?.termA
                  } days`}
              </span>
              <span className='weight-600'>Term B</span>
              <span>
                {verifyNumber(lineValue.termB, 'Term B') ??
                  (parseInt(lineValue.termA) === 0
                    ? `${parseInt(lineValue.cashbackPeriod) + 1} - ${
                        lineValue.termB
                      } days`
                    : `${parseInt(lineValue.termA) + 1} - ${
                        lineValue.termB
                      }+ days`)}
              </span>
            </div>
          </section>
          <section className='card'>
            <p>Rates</p>
            <div className='col-2'>
              <span className='weight-600'>Grace Rate</span>
              <span> {`${GRACE_RATE}%`}</span>
              <span className='weight-600'>Rate A</span>
              <span> {`${lineValue?.rateA}%`}</span>
              <span className='weight-600'>Rate B</span>
              <span>{`${lineValue?.rateB}%`}</span>
            </div>
          </section>
        </div>
      </div>
    </CreditAnalysisStyled>
  )
}

const CreditAnalysisStyled = styled.div`
  min-width: 900px;
  .margin-2 {
    margin: 1rem 2rem 1rem;
  }
  span {
    min-width: 100px;
  }

  .cont-gray {
    display: grid;
    background-color: #efefef;
    padding: 1rem 3rem 1rem 3rem;
    margin-top: 1em;
    border-radius: 11px;
    grid-template-columns: 40% 30% 30%;
    h4 {
      font-size: 18px;
      font-weight: bold;
    }
    .amount {
      font-size: 14px;
      font-weight: bold;
      margin: 1em 0em 0.1em 0em;
    }
    .cash {
      margin: 0;
      font-weight: bold;
      font-size: 1.5rem;
    }
    .weight-600 {
      font-weight: 600;
    }
    .card {
      background-color: white;
      margin: 1em;
      border-radius: 11px;
      padding: 2em;
      p {
        margin: 0 0 0.5em;
        font-weight: bold;
      }
    }
    .currency {
      color: #782048;
    }
    .col-2 {
      display: grid;
      grid-template-columns: 60% 40%;
    }
    .center-items {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`

export default LineCard
