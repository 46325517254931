import { Button } from '@keoworld/gbl-ui-kit'
import styled from 'styled-components'

const IconButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  height: 36px;
`

export default IconButton
