const formatInitialForm = (customer) => {
  let clients = ''
  customer.clients?.forEach((client) => {
    if (!clients) clients = `${clients}${client.name}`
    else clients = `${clients},${client.name}`
  })

  const formatedOwners = customer.owners?.map((owner) => {
    const newOwner = {
      id: owner.id,
      locationId: owner.location.id,
      'name:owner': owner.name,
      'email:owner': owner.email,
      'address:owner.location': owner.location?.address,
      'mobile:owner': owner.mobile,
      'detail:owner.location': owner.location?.detail,
      'title:owner': owner.title,
      'city:owner.location': owner.location?.city,
      'state:owner.location': owner.location?.state,
      'zip:owner.location': owner.location?.zip,
      'ownershipPercentage:owner': owner.ownershipPercentage,
      'birthdate:owner': owner.birthdate,
      'ssn:owner': owner.ssn
    }
    return newOwner
  })

  const form = {
    customerId: customer.id,
    locationId: customer.location?.id,
    statementId: customer.statement?.id,
    contactId: customer.contact?.id,
    loanRequestId: customer.loanRequest?.id,
    name: customer.name,
    email: customer.email,
    dba: customer.dba,
    startedAt: customer.startedAt,
    phone: customer.phone,
    taxId: customer.taxId,
    'address:location': customer.location?.address,
    fax: customer.fax,
    'detail:location': customer.location?.detail,
    'state:location': customer.location?.state,
    'zip:location': customer.location?.zip,
    businessType: customer.businessType,
    'city:location': customer.location?.city,
    incorporationState: customer.incorporationState,
    'addressType:location': customer.location?.addressType,
    employees: customer.employees,
    website: customer.website,
    clients: clients,
    owners: formatedOwners,
    'cashSalesPercentage:statement': customer.statement?.cashSalesPercentage,
    'mainSupplier:statement': customer.statement?.mainSupplier,
    'internationalSalesPercentage:statement':
      customer.statement?.internationalSalesPercentage,
    'monthlySales:statement': customer.statement?.monthlySales,
    'creditSalesPercentage:statement':
      customer.statement?.creditSalesPercentage,
    'yearlySales:statement': customer.statement?.yearlySales,
    'nationalSalesPercentage:statement':
      customer.statement?.nationalSalesPercentage,
    'projectionNextYearlySales:statement':
      customer.statement?.projectionNextYearlySales,
    'inventory:statement': customer.statement?.inventory,
    'averageTicketItem:statement': customer.statement?.averageTicketItem,
    'legalIssues:statement': customer.statement?.legalIssues,
    'legalIssuesDischarged:statement':
      customer.statement?.legalIssuesDischarged,
    'legalIssuesDischargedAt:statement':
      customer.statement?.legalIssuesDischargedAt,
    'amount:loanRequest': customer.loanRequest?.amount,
    'fundsUse:loanRequest': customer.loanRequest?.fundsUse,
    'firstName:contact': customer?.contact?.firstName,
    'lastName:contact': customer?.contact?.lastName,
    'email:contact': customer?.contact?.email,
    'mobilePhone:contact': customer?.contact?.mobilePhone
  }
  return { form }
}

const updateFormClients = async (clients, oldClients = []) => {
  let array = clients?.split(',') ?? []
  let clientsSend = []
  array.forEach((name) => {
    const names = oldClients.map((itm) => itm.name)
    const idx = names.indexOf(name)
    if (idx !== -1) clientsSend.push(oldClients[idx])
    else clientsSend.push({ name })
  })
  return clientsSend
}

const formatCreateOwner = (form) => {
  const formatForm = {
    birthdate: form['birthdate:owner'],
    email: form['email:owner'],
    location: {
      address: form['address:owner.location'],
      detail: form['detail:owner.location'],
      city: form['city:owner.location'],
      state: form['state:owner.location'],
      zip: form['zip:owner.location']
    },
    mobile: form['mobile:owner'],
    name: form['name:owner'],
    ownershipPercentage: form['ownershipPercentage:owner'],
    ssn: form['ssn:owner'],
    title: form['title:owner']
  }
  return formatForm
}

export { formatInitialForm, updateFormClients, formatCreateOwner }
