import { Icon } from '@keoworld/gbl-ui-kit'
import styled from 'styled-components'

const Chip = ({ label, onClose }) => {
  return (
    <ChipStyled>
      <span>{label}</span>
      <Icon name='close' onClick={onClose} />
    </ChipStyled>
  )
}

const ChipStyled = styled.div`
  border-radius: 30px;

  padding: 5px 10px;

  display: flex;
  align-items: center;

  ${({ theme }) => theme.colors.grayShades[700]};

  text-transform: capitalize;

  .icon {
    margin-left: 5px;
    cursor: pointer;
  }
`

export default Chip
