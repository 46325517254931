import { Input, Select, Textarea } from "@keoworld/gbl-ui-kit"

export const INPUT_TYPES = {
  TEXT_FIELD: "textField",
  SELECT: "select",
  TEXT_AREA: "textArea"
}

const FormInputs = {
  [INPUT_TYPES.TEXT_FIELD]: (props) => <Input {...props} />,
  [INPUT_TYPES.SELECT]: ({ options, value = "", ...props }) => {
    return (
      <Select value={value} {...props}>
        <option disabled value="">
          Select an option
        </option>
        {options.map(({ label, value }, index) => (
          <option key={`option-${index}`} value={value}>
            {label}
          </option>
        ))}
      </Select>
    )
  },
  [INPUT_TYPES.TEXT_AREA]: (props) => <Textarea {...props} />
}

/**
 *
 * @param {object} props
 * @param {object} props.formValues
 * @param {function} props.setFormValues
 * @param {function} props.isValidating
 * @returns
 */

 /* this form is used when errors are sent, this form does not validate */
 const FormErrorHandler = (params) => {
  const {
    schema = [],
    formValues,
    setFormValues,
    formErrors,
  } = params
  const onHandleChange = (event) => {
    const { name, value } = event.target
    setFormValues({ ...formValues, [name]: value })
  }

  return (
    <>
      {schema.map(
        (
          {
            children,
            validations = [],
            inputType = INPUT_TYPES.TEXT_FIELD,
            errorMessage,
            ...props
          },
          index
        ) => {
          const InputElement = FormInputs[inputType] ?? <></>
          const value = formValues[props.name] ?? ""
          const error = formErrors[props.name]
          return (
            <InputElement
              key={`form-input-${index}`}
              onChange={onHandleChange}
              value={value}
              error={error ? errorMessage || "Required Field" : false }
              {...props}
            />
          )
        }
      )}
    </>
  )
}

export default FormErrorHandler
