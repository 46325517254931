import { Formatters, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import PaginatedCircles from 'components/paginationCircles'
import { CustomerDetailContext } from 'providers/customer-detail'
import { useState } from 'react'
import { useContext, useEffect } from 'react'
import { getCommentFind } from 'services/comments'
import styled from 'styled-components'
import { CUSTOMER_STATES } from 'utils/schemas/customer'

const COLOR_STATE = {
  OnBoarding: '#751644',
  Operation: '#00575B',
  Underwriting: '#FF0045',
  Legal: '#FFBD2E',
  Disbursement: '#35C7C6',
  Collection: '#54022E',
  Active: '#00575B'
}
const initialFilter = { page: 0, limit: 6 }

const CustomerComments = () => {
  const { customer } = useContext(CustomerDetailContext)
  const [comments, setcomments] = useState([])
  const [totalComments, setTotalComments] = useState(0)
  const [filter, setFilter] = useState(initialFilter)
  const [currentPage, setCurrentPage] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setFilter({ ...filter, page: currentPage })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  useEffect(() => {
    const getComments = async () => {
      setLoading(true)
      const { data } = await getCommentFind(customer.id, filter)
      setcomments(data.data)
      setTotalComments(data.count)
      setLoading(false)
    }
    getComments()
  }, [customer.id, filter])

  const validateSpaceEmpty = (text) => {
    if (/^ *$/.test(text)) {
      return true
    }
    return false
  }

  return (
    <CustomerCommentsStyled>
      {loading && (
        <div className='center-loading'>
          <LoadingAnimation />
        </div>
      )}
      {!loading && (
        <div>
          <h3>Comments</h3>
          {comments.length > 0 ? (
            <div>
              <section className='container-box'>
                {Object.values(comments).map((element) => {
                  return (
                    <div className='card' key={element.id}>
                      <span className='state'>
                        <StateStyled
                          theme={
                            COLOR_STATE[CUSTOMER_STATES[element.wfState]?.stage]
                          }
                        >
                          {CUSTOMER_STATES[element.wfState]?.stateLabel}
                        </StateStyled>
                      </span>
                      <span className='container'>
                        <p className='name'>{element.username}</p>
                        <p className='date'>
                          {Formatters.dateFormat(element.createdAt)}
                        </p>
                      </span>
                      <p className='message'>
                        {validateSpaceEmpty(element.comment)
                          ? 'No comment'
                          : element.comment}
                      </p>
                    </div>
                  )
                })}
              </section>
              {totalComments > initialFilter.limit && (
                <PaginatedCircles
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalPages={Math.ceil(totalComments / initialFilter.limit)}
                />
              )}
            </div>
          ) : (
            <p>There are no comments to show.</p>
          )}
        </div>
      )}
    </CustomerCommentsStyled>
  )
}

const StateStyled = styled.h4`
  font-weight: 400;
  font-size: 0.563rem;
  line-height: 0.844rem;
  background-color: ${(props) => `${props.theme}33`} !important;
  color: ${(props) => props.theme} !important;
  border: 1px solid ${(props) => props.theme} !important;
  border-radius: 10px;
  padding: 0.313rem 0.625rem 0.313rem 0.625rem;
`

const CustomerCommentsStyled = styled.section`
  padding: 2rem;
  h3 {
    margin-bottom: 1rem;
  }
  .container-box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    .card {
      margin: 0.5rem;
      padding: 1.688rem;
      border: 1px solid #d9d9d9;
      border-radius: 10px;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    }
    .container {
      display: flex;
      margin-top: 0.813rem;
      justify-content: space-between;
    }
    .state {
      display: flex;
    }
    .name {
      margin: 0;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.313;
    }
    .date {
      margin: 0;
      font-size: 0.75rem;
      line-height: 1.125rem;
      font-weight: 400;
      color: #979797;
    }
    .message {
      text-align: justify;
      margin-top: 0.563rem;
      margin-bottom: 0.563rem;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.313rem;
    }
  }
  ul {
    margin-right: 0.5rem;
  }
`

export default CustomerComments
