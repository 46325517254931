import { Icon } from '@keoworld/gbl-ui-kit'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

/*  This component is subject to change to unify
    with the pagination.js component  */

const PaginatedCircles = ({
  currentPage,
  setCurrentPage,
  totalPages,
  pageRangeDisplayed = 5
}) => {
  const [pageCountArr, setPageCountArr] = useState([])

  useEffect(() => {
    let i = 0
    let arr = []
    while (i < totalPages) {
      arr.push(i)
      i++
    }
    setPageCountArr(arr)
  }, [totalPages])

  const nextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1)
    }
  }

  const previousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1)
    }
  }

  return (
    <Pagination>
      <ul>
        <li className="previous icon" onClick={() => previousPage()}>
          <Icon type="outlined" name="chevron_left" />
        </li>
        {pageCountArr.map((e) => {
          return (
            <li
              key={e}
              onClick={() => setCurrentPage(e)}
              className={e === currentPage ? 'selected' : ''}
            >
              {/* {e + 1} */}
            </li>
          )
        })}
        <li className="next icon" onClick={() => nextPage()}>
          <Icon type="outlined" name="chevron_right" />
        </li>
      </ul>
    </Pagination>
  )
}

const Pagination = styled.div`
  margin: 1rem auto;
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ul {
    display: flex;
    margin-left: auto;
    justify-content: end;
    align-items: center;
    list-style: none;
    li {
      cursor: pointer;
      margin-left: 0.3rem;
      margin-right: 0.3rem;
      border: 0;
      border-radius: 50%;
      background-color: #2f021a79;
      width: 10px;
      height: 10px;
      line-height: 30px;
    }
    li.selected {
      background-color: #2f021a;
    }
    li.previous {
      background-color: #ff013d4d !important;
      color: white;
      width: 30px;
      height: 30px;
    }
    li.previous:hover {
      background-color: #ff013d !important;
      color: white;
      border-radius: 50%;
    }
    li.next {
      background-color: #ff013d4d !important;
      color: white;
      width: 30px;
      height: 30px;
    }
    li.next:hover {
      background-color: #ff013d !important;
      color: white;
      border-radius: 50%;
    }
  }
`

export default PaginatedCircles
