import styled from 'styled-components';
import { Button } from '@keoworld/gbl-ui-kit';
import { useEffect, useRef, useState, useCallback } from 'react';

/**
 *
 * @param {object} props
 * @param {function} props.handleFiles - Recieve uploaded files
 * @returns
 */
const DragDropFile = ({ handleFiles, formatsAccepted = ['*'] }) => {
  const [dragging, setDragging] = useState(false);

  const dropRef = useRef();
  const inputRef = useRef();

  const handleUpload = () => inputRef.current?.click();

  const handleInput = (e) => {
    try {
      e.preventDefault();
      const files = Array.from(e.target?.files);
      files.forEach((file) => {
        if (file.size > 3000000) {
          throw new Error('Archivo pesado');
        }
      });
      handleFiles(files);
      // if (files && onChange) onChange(files)
    } catch (error) {
      alert(error.message);
    }
  };

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      handleFiles(e?.dataTransfer?.files);
      setDragging(false);
    },
    [handleFiles]
  );

  const handleDrag = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDragIn = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  }, []);

  const handleDragOut = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  }, []);

  useEffect(() => {
    const dropBox = dropRef.current;

    // useCallback needed to hold functions references
    dropBox.removeEventListener('dragenter', handleDragIn);
    dropBox.removeEventListener('dragleave', handleDragOut);
    dropBox.removeEventListener('dragover', handleDrag);
    dropBox.removeEventListener('drop', handleDrop);

    dropBox.addEventListener('dragenter', handleDragIn);
    dropBox.addEventListener('dragleave', handleDragOut);
    dropBox.addEventListener('dragover', handleDrag);
    dropBox.addEventListener('drop', handleDrop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DragDropStyled ref={dropRef} dragging={dragging} className="w-100">
      <p>Drag or select the files</p>
      <input
        ref={inputRef}
        name='documentName'
        accept={formatsAccepted}
        onChange={handleInput}
        type='file'
        hidden
        multiple
      />
      <Button onClick={() => handleUpload()}>Select</Button>
    </DragDropStyled>
  );
};

const DragDropStyled = styled.div`
  border: 2px dashed grey;
  border-radius: 10px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${({ dragging }) => (dragging ? 'gray' : 'none')};
  opacity: ${({ dragging }) => (dragging ? '0.3' : '1')};
`;

export default DragDropFile;
