import styled from 'styled-components'
import { capitalize } from 'utils/functions/form-data'
import { CUSTOMER_STAGES } from 'utils/schemas/customer'

const buttons = Object.entries(CUSTOMER_STAGES).map(([value, label]) => {
  return { label, value }
})

const StageFilter = ({ stage, setStage }) => {
  return (
    <DashboardFilterStyled>
      <button
        className={`filter ${stage === '' ? 'selected' : ''}`}
        onClick={() => setStage('')}
      >
        All
      </button>
      {buttons.map((object, index) => {
        const { label, value } = object

        const onHandleClick = () => setStage(value)

        return (
          <button
            key={`btn-${index}`}
            className={`filter ${stage === value ? 'selected' : ''}`}
            onClick={onHandleClick}
          >
            {capitalize(label)}
          </button>
        )
      })}
    </DashboardFilterStyled>
  )
}

const DashboardFilterStyled = styled.div`
  width: 100%;
  height: 50px;

  display: flex;
  ${({ theme }) => theme.colors.grayShades[300]};
  justify-content: center;
  padding-right: 2rem;

  button {
    border: none;
    position: relative;
    background-color: transparent;
    cursor: pointer;
    padding: 0 0.8rem;
    padding-top: 1rem;

    text-transform: capitalize;

    &.filter.selected::after {
      content: '';
      position: absolute;
      left: 11px;
      bottom: 0.1rem;

      background-color: ${({ theme }) =>
        theme.colors.grayShades[700].backgroundColor};

      width: 30%;
      height: 3px;
    }
  }
`

export default StageFilter
