import Comment from 'components/inputComment'
import { AlertContext } from 'providers/alert'
import { CustomerDetailContext } from 'providers/customer-detail'
import { LATERAL_MENU_OPTIONS, MenuBarContext } from 'providers/menu-bar'
import ModalUpload from 'providers/modal/modal-documents-upload'
import { useContext, useState } from 'react'
import { instanceTransition } from 'services/workflow'
import { SessionContext } from 'providers/session-manager'
import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import styled from 'styled-components'
import { CUSTOMER_STATES } from 'utils/schemas/customer'
import { LINE_STATUS } from 'utils/schemas/line'
import LineRangeViewer from 'containers/line-range-viewer'
import LineForm from 'containers/line/line-form'

const RateProposal = () => {
  const [lineForm, setLinesForm] = useState({})
  const [lineFormErrors, setLinesFormErrors] = useState({})
  const [commentForm, setCommentForm] = useState({ comment: null })
  const [commentError, setCommentError] = useState({})
  const [loadingAnimation, setLoadingAnimation] = useState(false)
  const [isValidatingForm, setIsValidatingForm] = useState(false)
  const [isValidatingComment, setIsValidatingComment] = useState(false)
  const { setAction, setSelectedOption } = useContext(MenuBarContext)
  const { userAuth } = useContext(SessionContext)
  const { customer, workflowStatus } = useContext(CustomerDetailContext)
  const [showModal, setShowModal] = useState(false)
  const { setAlert } = useContext(AlertContext)

  const validateFormErrors = (forms) => {
    let hasErrors = true
    let hasErrorComment = true
    if (Object.entries(lineFormErrors).length === 0) {
      hasErrors = false
    } else {
      setIsValidatingForm(true)
    }
    if (Object.entries(commentError).length === 0) {
      hasErrorComment = false
    } else {
      setIsValidatingComment(true)
    }
    return forms === 'all' ? !hasErrors && !hasErrorComment : !hasErrorComment
  }

  const handleTransition = async () => {
    const lineFormSend = {}
    Object.entries(lineForm).forEach((element) => {
      lineFormSend[element[0]] = Number(element[1])
    })
    lineFormSend.status = LINE_STATUS.Proposal
    setLoadingAnimation(true)
    let sendTransition = CUSTOMER_STATES.cust_rateProposal.transition.SEND
    const validationErrors = validateFormErrors('all')
    if (validationErrors) {
      try {
        await instanceTransition(
          workflowStatus?.workflowId,
          String(customer?.id),
          sendTransition.event,
          {
            ...lineFormSend,
            id: customer?.id,
            'wfState:comment': customer?.wfState,
            comment: commentForm?.comment,
            userUid: userAuth.uid,
            customerId: customer.id,
            'wfState:state': sendTransition.nextState
          }
        )
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD)
        setAction(false)
      } catch (err) {
        setAlert({
          title: 'Error',
          label: 'An error occurred, try again later',
          type: 'error'
        })
      }
    }
    setLoadingAnimation(false)
  }

  const handleReject = async () => {
    setLoadingAnimation(true)
    setIsValidatingComment(true)
    let sendTransition = CUSTOMER_STATES.cust_rateProposal.transition.REJECT
    if (commentForm.comment !== '' && commentForm.comment !== null) {
      try {
        await instanceTransition(
          workflowStatus?.workflowId,
          String(customer?.id),
          sendTransition.event,
          {
            id: customer?.id,
            customerId: customer.id,
            'wfState:comment': customer?.wfState,
            comment: commentForm?.comment,
            userUid: userAuth.uid,
            'wfState:state': sendTransition.nextState
          }
        )
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD)
        setAction(false)
      } catch (err) {
        setAlert({
          title: 'Error',
          label: 'An error occurred, try again later',
          type: 'error'
        })
      }
    } else {
      setAlert({
        title: 'Warning',
        label: 'Complete all requested fields',
        type: 'warning'
      })
    }
    setShowModal(false)
    setLoadingAnimation(false)
  }

  return (
    <>
      <UploadDocumentsStyled>
        <section className="margin-line">
          <LineForm
            title={'Rate Proposal'}
            formErrors={lineFormErrors}
            setFormErrors={setLinesFormErrors}
            formValues={lineForm}
            setFormValues={setLinesForm}
            isValidating={isValidatingForm}
          />
        </section>
        <LineRangeViewer
          title={'Range Viewer'}
          margin={'1rem 2.6rem 1rem'}
          line={lineForm}
        />
        <section>
          <div className="section-content col-12">
            <Comment
              formValues={commentForm}
              setFormValues={setCommentForm}
              formErrors={commentError}
              setFormErrors={setCommentError}
              isValidating={isValidatingComment}
            />
          </div>
        </section>
        <div className="action-group">
          <Button buttonType="grayButton" onClick={() => setAction(false)}>
            Cancel
          </Button>
          <Button
            disabled={loadingAnimation}
            className="send"
            onClick={() => handleTransition()}
          >
            {loadingAnimation ? <LoadingAnimation /> : 'Send'}
          </Button>
          <Button
            disabled={loadingAnimation}
            className="send"
            buttonType="grayButton"
            onClick={() => setShowModal(true)}
          >
            {loadingAnimation ? <LoadingAnimation /> : 'Close Prospect'}
          </Button>
        </div>
      </UploadDocumentsStyled>
      {showModal && (
        <ModalUpload
          setOpenModalUpload={setShowModal}
          messages={{
            msg: 'Are you sure you want to close the prospect? If you close the prospect you will not be able to resume the process',
            msgButton: 'Accept'
          }}
          handleSave={handleReject}
          isLoadingSubmit={loadingAnimation}
        />
      )}
    </>
  )
}

const UploadDocumentsStyled = styled.section`
  padding: 2rem;

  .col-12 {
    grid-template-columns: 1fr !important;
  }
  .margin-line {
    margin: 0 0.7rem 0;
  }
  .section-content {
    margin: 2rem 42px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    & > div {
      padding: 0 1rem;
      &:nth-child(3n + 1) {
        padding-left: 0;
      }
      &:nth-child(3n) {
        padding-right: 0;
      }
    }

    .textarea {
      grid-column: span 2;
    }

    @media (max-width: 780px) {
      grid-template-columns: 1fr 1fr;
      & > div {
        padding: 0 1rem;
        &:nth-child(2n + 1) {
          padding-left: 0;
        }
        &:nth-child(2n) {
          padding-right: 0;
        }
      }
    }
  }

  .action-group {
    margin: 2rem 42px 0;

    button + button {
      margin-left: 28px;
    }
    .send {
      float: right;
    }
  }
`

export default RateProposal
