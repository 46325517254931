import axios from 'axios'
import { ENDPOINTS } from './config'


/**
 *
 * @param {string} customerId
 * @returns
 */
 export const getLineFind = async (customerId) =>
    axios.get(ENDPOINTS.line.find, { params: { customerId } })


/**
 *
 * @param {string} lineId
 * @returns
 */
 export const getLineInfo = async (lineId) =>
    axios.get(ENDPOINTS.line.info, { params: { id: lineId } })
