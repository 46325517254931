import { doc, onSnapshot } from 'firebase/firestore'
import { createContext, useEffect, useState } from 'react'
import { auth, firestore } from 'services/firebase.controller'
import { APP_NAME, APP_VERSION } from 'utils/constants'

const initialContext = {
  userAuth: undefined,
  setUserAuth: undefined
}

export const SessionContext = createContext(initialContext)

export const SessionProvider = ({ children }) => {
  const [userAuth, setUserAuth] = useState()
  const [projectIsOutdated, setProjectIsOutdated] = useState(false)

  useEffect(() => {
    const onAuthChange = async (user) => setUserAuth(user)
    const unsubscribe = auth.onAuthStateChanged(onAuthChange)
    const unsubscribeDb = onSnapshot(doc(firestore, "products", APP_NAME ?? '' ), (doc) => {
      try {
        const { currentFrontendVersion } = doc.data()
        console.log(`Current version:${currentFrontendVersion}, local version:${APP_VERSION}`)
        if (checkIsOutdated(currentFrontendVersion, APP_VERSION)) {
          setProjectIsOutdated(true)
        }
      } catch(err) {
        console.log({ err })
      }
    })

    return () => {
      unsubscribe()
      try {
        if (firestore && APP_NAME) unsubscribeDb()
      } catch (err) {
        console.log('Version comparing error ->', err, err?.message)
      }
    }
  }, [])

  return (
    <SessionContext.Provider value={{ userAuth, setUserAuth, projectIsOutdated }}>
      {children}
    </SessionContext.Provider>
  )
}

const checkIsOutdated = (versionRemote, versionLocal) => {
  const arrRemote = versionRemote.split('.')
  const arrLocal = versionLocal.split('.')
  return arrLocal.find( (itm, idx) => {
    return Number(itm) < Number(arrRemote[idx])   
  })
}