import { Icon, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import CommentsBar from 'containers/comments-bar'
import FileForm, { DocumentSectionStyled } from 'containers/file-form'
import { CustomerDetailContext } from 'providers/customer-detail'
import { useCallback, useContext, useEffect, useState } from 'react'
import { getDocuments } from 'services/documents'
import styled from 'styled-components'
import { FILE_FORM_SCHEMAS, FILE_STAGES } from 'utils/schemas/documents.js'
import { Button } from '@keoworld/gbl-ui-kit'
import { downloadFile } from 'services/documents'
import { capitalize } from 'utils/functions/form-data'

const CustomerDocuments = () => {
  const { customer } = useContext(CustomerDetailContext)
  const [loading, setLoading] = useState(false)
  const [documents, setDocuments] = useState([])
  const [documentsByStages, setDocumentsByStages] = useState([])
  const [lateralBar, setOpenComments] = useState(false)
  const [openedSection, setOpenedSection] = useState({
    OnBoarding: true,
    Underwriting: false,
    Collection: false,
    Disbursement: false
  })

  const fetchFiles = useCallback(async () => {
    const { data } = await getDocuments(customer?.id)
    setDocuments(data)
    setLoading(false)
  }, [customer?.id])

  /* Fetch files effect */
  useEffect(() => {
    setLoading(true)
    fetchFiles()
  }, [fetchFiles])

  /* Segregate files by stage effect*/
  useEffect(() => {
    setDocumentsByStages([])
    Object.values(FILE_STAGES).forEach((itm) => {
      let state = documents.filter((element) => element.stage === itm)
      setDocumentsByStages((prev) => ({ ...prev, [itm]: state }))
    })
  }, [documents])

  if (loading) {
    return (
      <CustomerDocumentsStyled>
        <div className="center-loading">
          <LoadingAnimation />
        </div>
      </CustomerDocumentsStyled>
    )
  }
  return (
    <CustomerDocumentsStyled>
      {loading && (
        <div className="center-loading">
          <LoadingAnimation />
        </div>
      )}
      {!loading && (
        <section>
          {Object.entries(documentsByStages).map(([key, value]) => (
            <div className="file-section" key={key}>
              {value ? (
                <DocumentSectionStyled isOpen={openedSection[key]}>
                  <div
                    className="section-label"
                    onClick={() =>
                      setOpenedSection((prev) => ({
                        ...prev,
                        [key]: !prev[key]
                      }))
                    }
                  >
                    <Icon
                      name={
                        openedSection[key] ? 'radio_button_checked' : 'circle'
                      }
                    />
                    <h3>{capitalize(key)}</h3>
                  </div>
                  <div className="file-form-section">
                    {value.length > 0 ? (
                      <FileForm
                        controller={value.reduce(
                          (acc, itm) => ({
                            ...acc,
                            [itm?.typeId]: itm
                          }),
                          {}
                        )}
                        setController={() => {}}
                        schema={FILE_FORM_SCHEMAS[key] ?? []}
                      />
                    ) : (
                      <div>Not {key} documents yet.</div>
                    )}
                    <div className="files-viewer">
                      {value
                        ?.filter(
                          (itm) =>
                            itm.typeId ===
                            FILE_FORM_SCHEMAS.additional.fileTypeId
                        )
                        .map((itm, idx) => (
                          <div key={`file-${idx}`} className="file-preview">
                            <p className="image-p">{itm?.name}</p>
                            {itm?.id ? (
                              <Button
                                buttonType={'outline'}
                                className="icon-image"
                                onClick={() => downloadFile(itm.id, itm?.name)}
                              >
                                <Icon name="file_download" type="outlined" />
                              </Button>
                            ) : (
                              <></>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </DocumentSectionStyled>
              ) : (
                <></>
              )}
            </div>
          ))}
        </section>
      )}
      {lateralBar && <CommentsBar setOpenComments={setOpenComments} />}
    </CustomerDocumentsStyled>
  )
}

const CustomerDocumentsStyled = styled.section`
  padding: 2rem;
  .center-loading {
    height: 50vh;
    display: flex;
    align-items: center;
    div {
      width: 150px;
    }
  }

  .file-section {
    border-left: solid 2px
      ${({ theme }) => theme.colors.primary.dark.backgroundColor};
    margin: 1em 0;
    padding: 1em;
    position: relative;
  }
  //
  .files-viewer {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 40%;
    justify-content: center;
    margin-top: 1em;
    justify-content: flex-start;
    flex-basis: 60%;
  }

  .file-preview {
    border: solid 1px grey;
    border-radius: 1em;
    display: flex;
    max-height: 50%;
    margin: 0.5em;
  }

  .image-p {
    width: 150px;
    font-size: 12px;
    word-wrap: break-word;
    padding: 10px 5px 5px 5px;
  }
  .icon-image {
    width: 20px;
    height: 20px;
    margin: 5px;

    span {
      display: flex;
      margin: auto;
      justify-content: center;
      align-items: center;
      font-size: 15px;
    }
  }
  //
  h3 {
    padding-top: 1rem;
  }

  h5 {
    margin-top: 1rem;
  }

  .document {
    width: 65%;
  }
`

export default CustomerDocuments
