import { Button, Icon, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import DragDropFile from 'components/dragDropFile'
import Comment from 'components/inputComment'
import FileForm, { DocumentSectionStyled } from 'containers/file-form'
import { AlertContext } from 'providers/alert'
import { CustomerDetailContext } from 'providers/customer-detail'
import { LATERAL_MENU_OPTIONS, MenuBarContext } from 'providers/menu-bar'
import { SessionContext } from 'providers/session-manager'
import ModalUpload from 'providers/modal/modal-documents-upload'
import { useCallback, useContext, useEffect, useState } from 'react'
import { downloadFile, getDocuments, uploadDocument } from 'services/documents'
import { instanceTransition } from 'services/workflow'
import styled from 'styled-components'
import { capitalize, objectToFormData } from 'utils/functions/form-data'
import { CUSTOMER_STAGES, CUSTOMER_STATES } from 'utils/schemas/customer'
import {
  FILE_FORM_SCHEMAS,
  FILE_STAGES,
  FILE_STATES
} from 'utils/schemas/documents'
import { LINE_STATUS } from 'utils/schemas/line'
import { getLineFind } from 'services/lines'

const formatsAccepted = [
  '.pdf',
  'image/*',
  '.doc',
  '.docx',
  '.xlsx',
  '.xls',
  '.csv'
]

/**
 *
 * @param {string} currentSection
 * @returns
 */
const UploadDocuments = ({
  currentSection = 'OnBoarding',
  showAcceptanceCheck
}) => {
  const [files, setFiles] = useState({})
  const [commentForm, setCommentForm] = useState({ comment: null })
  const [commentError, setCommentError] = useState({})
  const [isValidatingComment, setIsValidatingComment] = useState(false)
  const [openedSection, setOpenedSection] = useState({})
  const [additionalFiles, setAdditionalFiles] = useState([])
  const [loading, setLoading] = useState({
    fetch: false,
    submit: false
  })
  const [showModal, setShowModal] = useState(false)
  const { setAlert } = useContext(AlertContext)

  const { setAction, setSelectedOption } = useContext(MenuBarContext)
  const { customer, workflowStatus, setCustomer } = useContext(
    CustomerDetailContext
  )
  const { userAuth } = useContext(SessionContext)
  const [isValidatingDoc, setIsValidatingDoc] = useState(false)
  const [lineValue, setLineValue] = useState({})

  const customerStateInfo = CUSTOMER_STATES[workflowStatus?.currentState]

  useEffect(() => {
    const indexCurrentSection =
      Object.keys(FILE_FORM_SCHEMAS).indexOf(currentSection)
    Object.keys(FILE_FORM_SCHEMAS).forEach((section, index) => {
      if (index <= indexCurrentSection) {
        if (section !== currentSection) {
          setOpenedSection((prev) => ({ ...prev, [section]: false }))
        } else {
          setOpenedSection((prev) => ({ ...prev, [section]: true }))
        }
      }
    })
  }, [currentSection])

  useEffect(() => {
    const setFirstLine = async () => {
      try {
        const { data } = await getLineFind(customer.id)
        setLineValue(data[0])
      } catch (error) {
        console.log(error)
      }
    }
    setFirstLine()
  }, [customer.id])

  const handleDrop = (inputFiles) => {
    const filesFiltered = Array.from(inputFiles)
      ?.filter((itm) => !additionalFiles.includes(itm))
      .map((file) => {
        file.fileTypeId = FILE_FORM_SCHEMAS.additional?.fileTypeId
        return file
      })
    setAdditionalFiles((prev) => [...prev, ...filesFiltered])
  }

  const handleSubmit = async () => {
    try {
      await uploadFileList(
        Object.values(files),
        customer,
        customerStateInfo?.stage
      )
      await uploadFileList(additionalFiles, customer, customerStateInfo?.stage)
    } catch (error) {
      setAlert({
        title: 'Error',
        label: 'An error occurred while uploading a document, try again later',
        type: 'error'
      })
    }
    setAction(false)
  }

  const validateFormErrors = () => {
    let hasErrorComment

    const section = Object.keys(openedSection).find(
      (itm) => itm === currentSection
    )
    const error = FILE_FORM_SCHEMAS[section].find(
      ({ fileTypeId, isRequired }) => !files[fileTypeId] && isRequired
    )

    if (error) {
      return (hasErrorComment = true)
    }

    Object.values(commentError).every((value) => {
      if (value) {
        hasErrorComment = true
        return false
      } else hasErrorComment = false
      return true
    })
    return hasErrorComment
  }

  const handleTransition = async () => {
    setIsValidatingComment(true)
    setIsValidatingDoc(true)
    const sendTransition = customerStateInfo?.transition.SEND
    const allApproved = showAcceptanceCheck
      ? !FILE_FORM_SCHEMAS[currentSection].find(
          (itm) => files[itm?.fileTypeId]?.acceptance !== 'accept'
        )
      : true
    const hasErrorComment = validateFormErrors()

    let body = {
      id: customer?.id,
      wfStage: sendTransition.nextStage,
      'wfState:state': sendTransition.nextState,
      comment: commentForm?.comment,
      userUid: userAuth.uid,
      'wfState:comment': customer?.wfState
    }
    if (currentSection === CUSTOMER_STAGES.Legal) {
      lineValue.status = LINE_STATUS.Active
      body = {
        ...body,
        ...lineValue
      }
    }

    if (allApproved && !hasErrorComment) {
      try {
        setLoading((prev) => ({ ...prev, submit: true }))
        await uploadFileList(
          Object.values(files),
          customer,
          customerStateInfo?.stage
        )
        await uploadFileList(
          additionalFiles,
          customer,
          customerStateInfo?.stage
        )
        await instanceTransition(
          workflowStatus?.workflowId,
          String(customer?.id),
          sendTransition.event,
          body
        )
        setLoading((prev) => ({ ...prev, submit: false }))
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD)
        setCustomer({})
        setAction(false)
      } catch (err) {
        const errorI2C = (
          JSON.parse(err?.response?.data?.body?.message)?.detail ?? ''
        ).split('-')
        if (errorI2C[0] === 'Response with invalid code detected. code: 57 ') {
          setAlert({
            title: 'Error',
            label:
              'An error occurred. The amount entered is not valid.',
            type: 'error'
          })
        } else {
          setAlert({
            title: 'Error',
            label: 'An error occurred, try again later',
            type: 'error'
          })
        }
      } finally {
        setLoading((prev) => ({ ...prev, submit: false }))
      }
    } else if (!allApproved) {
      setAlert({
        title: 'Warning',
        label: 'You must accept all documents.',
        type: 'warning'
      })
    }
  }

  const handleReject = async () => {
    setIsValidatingComment(true)
    setLoading((prev) => ({ ...prev, submit: true }))
    let sendTransition = customerStateInfo?.transition.REJECT
    if (commentForm.comment !== '' && commentForm.comment !== null) {
      try {
        await instanceTransition(
          workflowStatus?.workflowId,
          String(customer?.id),
          sendTransition.event,
          {
            id: customer?.id,
            customerId: customer.id,
            'wfState:comment': customer?.wfState,
            comment: commentForm?.comment,
            userUid: userAuth.uid,
            'wfState:state': sendTransition.nextState
          }
        )
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD)
        setAction(false)
      } catch (err) {
        setAlert({
          title: 'Error',
          label: 'An error occurred, try again later',
          type: 'error'
        })
      }
    } else {
      setAlert({
        title: 'Warning',
        label: 'Complete all requested fields',
        type: 'warning'
      })
    }
    setShowModal(false)
    setLoading((prev) => ({ ...prev, submit: false }))
  }

  const fetchFiles = useCallback(async () => {
    const { data } = await getDocuments(customer?.id)
    const additional = data.filter(
      (itm) => itm.typeId === FILE_FORM_SCHEMAS.additional?.fileTypeId
    )
    const fileForm = data.reduce(
      (acc, itm) => ({ ...acc, [itm?.typeId]: itm }),
      {}
    )
    setFiles(fileForm)
    setAdditionalFiles(additional)
  }, [customer?.id])

  const dropImage = (idx) => {
    setAdditionalFiles((itm) => itm.filter((_, index) => index !== idx))
  }

  useEffect(() => {
    fetchFiles()
  }, [fetchFiles])

  return (
    <>
      <UploadDocumentsStyled>
        <section>
          {Object.keys(openedSection).map((itm, idx) => (
            <div className="file-section" key={`file-section-${idx}`}>
              <DocumentSectionStyled isOpen={openedSection[itm]}>
                <div
                  className="section-label"
                  key={`section-label-${idx}`}
                  onClick={() =>
                    setOpenedSection((prev) => ({ ...prev, [itm]: !prev[itm] }))
                  }
                >
                  <Icon
                    key={`icon-${idx}`}
                    name={
                      openedSection[itm] ? 'radio_button_checked' : 'circle'
                    }
                  />
                  <h3>{capitalize(itm)} documentation</h3>
                </div>
                <div
                  className="file-form-section"
                  key={`file-form-section-${idx}`}
                >
                  <FileForm
                    controller={files}
                    setController={setFiles}
                    schema={FILE_FORM_SCHEMAS[itm]}
                    withCheck={showAcceptanceCheck && itm === currentSection}
                    withUpload={itm === currentSection}
                    deleteFile={itm === currentSection}
                    isValidatingDoc={isValidatingDoc && itm === currentSection}
                  />
                </div>
              </DocumentSectionStyled>
            </div>
          ))}
          <div className="file-section no-padding">
            <DocumentSectionStyled isOpen={openedSection.OnBoarding}>
              <div className="section-label">
                <Icon name={'circle'} />
              </div>
            </DocumentSectionStyled>
          </div>
        </section>
        <section className="additional-section">
          <h3>Additional Documents</h3>
          <div className="additional-upload">
            <div className="files-drag-drop">
              <DragDropFile
                handleFiles={handleDrop}
                formatsAccepted={formatsAccepted}
              />
            </div>
            <div className="files-viewer">
              {additionalFiles.map((itm, idx) => (
                <div key={`file-${idx}`} className="file-preview">
                  <p className="image-p">{itm?.name}</p>
                  {itm?.id ? (
                    <Button
                      buttonType={'outline'}
                      className="icon-image"
                      onClick={() => downloadFile(itm.id, itm?.name)}
                    >
                      <Icon name="file_download" type="outlined" />
                    </Button>
                  ) : (
                    <Button
                      buttonType={'outline'}
                      className="icon-image"
                      onClick={() => dropImage(idx)}
                    >
                      <Icon name="close" type="outlined" />
                    </Button>
                  )}
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="comments-section">
          <Comment
            formValues={commentForm}
            setFormValues={setCommentForm}
            formErrors={commentError}
            setFormErrors={setCommentError}
            isValidating={isValidatingComment}
          />
        </section>
        <div className="action-group">
          <Button buttonType="grayButton" onClick={() => setAction(false)}>
            Close
          </Button>
          <Button disabled={loading?.submit} onClick={() => handleSubmit()}>
            Save
          </Button>
          <Button
            buttonType="green"
            className="send"
            disabled={loading?.submit}
            onClick={() => handleTransition()}
          >
            {loading?.submit ? <LoadingAnimation /> : 'Send'}
          </Button>
          <Button
            className="send"
            disabled={loading?.submit}
            buttonType="grayButton"
            onClick={() => setShowModal(true)}
          >
            {loading?.submit ? <LoadingAnimation /> : 'Close Prospect'}
          </Button>
        </div>
      </UploadDocumentsStyled>
      {showModal && (
        <ModalUpload
          setOpenModalUpload={setShowModal}
          messages={{
            msg: 'Are you sure you want to close the prospect? If you close the prospect you will not be able to resume the process',
            msgButton: 'Accept'
          }}
          handleSave={handleReject}
          isLoadingSubmit={loading?.submit}
        />
      )}
    </>
  )
}

/**
 *
 * @param {object} formFileList
 * @param {object} customer
 */
const uploadFileList = async (
  formFileList,
  customer,
  stage = FILE_STAGES.OnBoarding
) => {
  try {
    await Promise.all(
      formFileList.map(async (file) => {
        /* Upload file */
        if (file && file instanceof File) {
          const fileData = {
            file,
            name: file.name,
            extension: file.type,
            customerId: customer?.id,
            stage,
            type: file?.fileTypeId,
            status:
              file?.acceptance === 'accept'
                ? FILE_STATES.APPROVED
                : file?.acceptance === 'reject'
                ? FILE_STATES.REJECTED
                : FILE_STATES.PENDING
          }
          const formData = objectToFormData(fileData)
          return await uploadDocument(formData)
        }
        return null
      })
    )
  } catch (err) {
    console.log(err)
  }
}

const UploadDocumentsStyled = styled.section`
  padding: 2rem;

  .file-section {
    border-left: solid 2px
      ${({ theme }) => theme.colors.primary.dark.backgroundColor};
    margin: 1em 0;
    position: relative;
    padding: 1em;
  }

  .no-padding {
    padding: 0;
  }

  .files-viewer,
  .files-drag-drop {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 40%;
    justify-content: center;
    margin-top: 1em;
    .w-100 {
      width: 100%;
    }
  }

  .files-viewer {
    flex-basis: 60%;
  }

  .file-preview {
    border: solid 1px grey;
    border-radius: 1em;
    display: flex;
    max-height: 50%;
    margin: 0.5em;
  }

  .comments-section {
    margin: 2.3em;
    margin-top: 3em;
  }

  .additional-section {
    margin: 2.3em;

    .additional-upload {
      display: flex;
    }
  }
  .image-p {
    width: 150px;
    font-size: 12px;
    word-wrap: break-word;
    padding: 10px 5px 5px 5px;
  }
  .icon-image {
    width: 20px;
    height: 20px;
    margin: 5px;

    span {
      display: flex;
      margin: auto;
      justify-content: center;
      align-items: center;
      font-size: 15px;
    }
  }
  .action-group {
    margin: 2rem 42px 0;

    button + button {
      margin-left: 28px;
    }
    .send {
      float: right;
    }
  }
`

export default UploadDocuments
