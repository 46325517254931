import ProtectedRoute from 'components/protected-route'
import Main from 'pages/main'
import SignIn from 'pages/sign-in'
import { Route, Routes } from 'react-router-dom'
import Router from 'utils/router'

const App = () => {
  return (
    <Routes>
      <Route path={Router.Root} element={<ProtectedRoute />}>
        <Route path={Router.Root} element={<Main />} />
      </Route>
      <Route path={Router.SignIn} element={<SignIn />} />
    </Routes>
  )
}

export default App
