import { Button, Icon } from '@keoworld/gbl-ui-kit'
import DragDropFile from 'components/dragDropFile'
import FileForm, { DocumentSectionStyled } from 'containers/file-form'
import { CustomerDetailContext } from 'providers/customer-detail'
import { useCallback, useContext, useEffect, useState } from 'react'
import { getDocuments, downloadFile } from 'services/documents'
import styled from 'styled-components'
import { capitalize } from 'utils/functions/form-data'
import { FILE_FORM_SCHEMAS } from 'utils/schemas/documents'

const formatsAccepted = [
  '.pdf',
  'image/*',
  '.doc',
  '.docx',
  '.xlsx',
  '.xls',
  '.csv'
]
const initialSections = {
  OnBoarding: false,
  Operation: false
}

/**
 *
 * @param {string} currentSection
 * @returns
 */
const UploadDocuments = ({
  currentSection = 'OnBoarding',
  showAcceptanceCheck,
  additionalFiles,
  setAdditionalFiles,
  files,
  setFiles,
  isValidatingDoc,
  setError
}) => {
  const [openedSection, setOpenedSection] = useState({
    ...initialSections,
    [currentSection]: true
  })
  const { customer } = useContext(CustomerDetailContext)

  const handleDrop = (inputFiles) => {
    const filesFiltered = Array.from(inputFiles)
      ?.filter((itm) => !additionalFiles.includes(itm))
      .map((file) => {
        file.fileTypeId = FILE_FORM_SCHEMAS.additional?.fileTypeId
        return file
      })
    setAdditionalFiles((prev) => [...prev, ...filesFiltered])
  }

  const fetchFiles = useCallback(async () => {
    const { data } = await getDocuments(customer?.id)
    const additional = data.filter(
      (itm) => itm.typeId === FILE_FORM_SCHEMAS.additional?.fileTypeId
    )
    const fileForm = data.reduce(
      (acc, itm) => ({ ...acc, [itm?.typeId]: itm }),
      {}
    )
    setFiles(fileForm)
    setAdditionalFiles(additional)
  }, [customer?.id, setAdditionalFiles, setFiles])

  const dropImage = (idx) => {
    setAdditionalFiles((itm) => itm.filter((_, index) => index !== idx))
  }

  useEffect(() => {
    fetchFiles()
  }, [fetchFiles])

  return (
    <UploadDocumentsStyled>
      <section>
        {Object.keys(openedSection).map((itm) => (
          <div className='file-section' key={`itm-${itm}`}>
            <DocumentSectionStyled isOpen={openedSection[itm]}>
              <div
                className='section-label'
                onClick={() =>
                  setOpenedSection((prev) => ({ ...prev, [itm]: !prev[itm] }))
                }
              >
                <Icon
                  name={openedSection[itm] ? 'radio_button_checked' : 'circle'}
                />
                <h3>{capitalize(itm)} documentation</h3>
              </div>
              <div className='file-form-section'>
                <FileForm
                  controller={files}
                  setController={setFiles}
                  schema={FILE_FORM_SCHEMAS[itm]}
                  withCheck={showAcceptanceCheck}
                  withUpload={itm === currentSection}
                  deleteFile={itm === currentSection}
                  isValidatingDoc={isValidatingDoc && itm === currentSection}
                />
              </div>
            </DocumentSectionStyled>
          </div>
        ))}
        <div className='file-section no-padding'>
          <DocumentSectionStyled isOpen={openedSection.OnBoarding}>
            <div className='section-label'>
              <Icon name={'circle'} />
            </div>
          </DocumentSectionStyled>
        </div>
      </section>
      <section className='additional-section'>
        <h3>Additional Documents</h3>
        <div className='additional-upload'>
          <div className='files-drag-drop'>
            <DragDropFile
              handleFiles={handleDrop}
              formatsAccepted={formatsAccepted}
            />
          </div>
          <div className='files-viewer'>
            {additionalFiles.map((itm, idx) => (
              <div key={`file-${idx}`} className='file-preview'>
                <p className='image-p'>{itm?.name}</p>
                {itm?.id ? (
                  <Button
                    buttonType={'outline'}
                    className='icon-image'
                    onClick={() => downloadFile(itm.id, itm?.name)}
                  >
                    <Icon name='file_download' type='outlined' />
                  </Button>
                ) : (
                  <Button
                    buttonType={'outline'}
                    className='icon-image'
                    onClick={() => dropImage(idx)}
                  >
                    <Icon name='close' type='outlined' />
                  </Button>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
    </UploadDocumentsStyled>
  )
}

const UploadDocumentsStyled = styled.section`
  padding: 2rem;

  .file-section {
    border-left: solid 2px
      ${({ theme }) => theme.colors.primary.dark.backgroundColor};
    margin: 1em 0;
    position: relative;
    padding: 1em;
  }

  .no-padding {
    padding: 0;
  }

  .files-viewer,
  .files-drag-drop {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 40%;
    justify-content: center;
    margin-top: 1em;
    .w-100 {
      width: 100%;
    }
  }

  .files-viewer {
    flex-basis: 60%;
  }

  .file-preview {
    border: solid 1px grey;
    border-radius: 1em;
    display: flex;
    max-height: 50%;
    margin: 0.5em;
  }

  .comments-section {
    margin: 2.3em;
  }

  .additional-section {
    margin: 2.3em;

    .additional-upload {
      display: flex;
    }
  }
  .image-p {
    width: 150px;
    font-size: 12px;
    word-wrap: break-word;
    padding: 10px 5px 5px 5px;
  }
  .icon-image {
    width: 20px;
    height: 20px;
    margin: 5px;

    span {
      display: flex;
      margin: auto;
      justify-content: center;
      align-items: center;
      font-size: 15px;
    }
  }
  .action-group {
    margin: 2rem 42px 0;

    button + button {
      margin-left: 28px;
    }
    .send {
      float: right;
    }
  }
`

export default UploadDocuments
