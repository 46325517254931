import { useEffect, useState } from 'react'
import styled from 'styled-components'

const LineRangeViewer = ({
  title = 'Line',
  margin = '2rem 2.5rem 1rem',
  line
}) => {
  const [lineValue, setLineValue] = useState({})

  useEffect(() => {
    setLineValue(line)
  }, [line])

  const verifyNumber = (field, name) => {
    if (!field) return `Type in the field ${name}`
    if (
      lineValue.termA === '' ||
      lineValue.termB === '' ||
      lineValue.cashbackPeriod === ''
    )
      return `Miss a field`
    const fieldNumber = parseInt(field)
    if (isNaN(fieldNumber)) return `Type a number in ${name}`
    if (fieldNumber === 0) return 'Does not apply'
    return undefined
  }

  return (
    <LineRangeViewerStyled margin={margin}>
      <h4>{title}</h4>
      <div className='cont-gray'>
        <span className='card'>
          <p>Grace Period</p>
          {verifyNumber(lineValue.cashbackPeriod, 'Grace Period') ??
            `1 - ${lineValue.cashbackPeriod} days`}
        </span>
        <span className='card'>
          <p>Term A </p>
          {verifyNumber(lineValue.termA, 'Term A') ??
            `${parseInt(lineValue.cashbackPeriod) + 1} - ${
              lineValue.termA
            } days`}
        </span>
        <span className='card'>
          <p>Term B </p>
          {verifyNumber(lineValue.termB, 'Term B') ??
            (parseInt(lineValue.termA) === 0
              ? `${parseInt(lineValue.cashbackPeriod) + 1} - ${
                  lineValue.termB
                } days`
              : `${parseInt(lineValue.termA) + 1} - ${lineValue.termB}+ days`)}
        </span>
      </div>
    </LineRangeViewerStyled>
  )
}

const LineRangeViewerStyled = styled.div`
  margin: ${(props) => props.margin};
  background-color: #efefef;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 11px;

  h4 {
    font-size: 15px;
    font-weight: bold;
    margin: 1rem 2.1rem 0.1rem;
  }
  .cont-gray {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    .card {
      background-color: white;
      margin: 1rem 2rem;
      border-radius: 11px;
      padding: 1rem;
      p {
        margin: 0 0 0.5em;
        font-weight: bold;
      }
    }
  }
`

export default LineRangeViewer
