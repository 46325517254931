import React from 'react'
import ReactPaginate from 'react-paginate'
import styled from 'styled-components'

/**
 * Component that renders a pagination component
 * @param {Object} params
 * @returns {ReactPaginate} - Pagination component
 */
const PaginatedItems = ({
  currentPage,
  handlePageClick,
  pageCount,
  pageRangeDisplayed = 5
}) => {
  return (
    <Pagination
      breakLabel='...'
      nextLabel='>'
      onPageChange={handlePageClick}
      pageRangeDisplayed={pageRangeDisplayed}
      pageCount={pageCount}
      previousLabel='<'
      forcePage={currentPage}
      renderOnZeroPageCount={null}
    />
  )
}

const Pagination = styled(ReactPaginate)`
  display: flex;
  justify-content: space-around;

  width: 400px;
  padding: 0;

  list-style: none;
  li {
    border: 1px solid black;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;

    &.selected {
      font-weight: bold;
      border: 2px solid black;
    }
    &.previous {
      background-color: #efefef;
    }
    &.next {
      background-color: #efefef;
    }
    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
`

export default PaginatedItems
