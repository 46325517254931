import { Icon } from '@keoworld/gbl-ui-kit'
import { MenuBarContext, LATERAL_MENU_OPTIONS } from 'providers/menu-bar'
import { useContext } from 'react'
import styled from 'styled-components'

const MenuBar = () => {
  const {
    selectedOption,
    setSelectedOption,
    setAction
  } = useContext(MenuBarContext)

  const lateralBarOptions = [
    {
      condition:
        selectedOption === LATERAL_MENU_OPTIONS.DASHBOARD ||
        selectedOption === LATERAL_MENU_OPTIONS.ONBOARDING_CREATE ||
        selectedOption === LATERAL_MENU_OPTIONS.ONBOARDING_DETAIL,
      action: () => setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD),
      label: 'Onboarding',
      icon: <Icon name='group' type='outlined' />
    },
    {
      condition:
        selectedOption === LATERAL_MENU_OPTIONS.DASHBOARD_CUSTOMER ||
        selectedOption === LATERAL_MENU_OPTIONS.CUSTOMER_DETAIL,
      action: () => setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_CUSTOMER),
      label: 'Customers',
      icon: <Icon name='handshake' type='outlined' />
    },
    {
      condition: selectedOption === LATERAL_MENU_OPTIONS.HELP,
      action: () => setSelectedOption(LATERAL_MENU_OPTIONS.HELP),
      label: 'Configurations',
      icon: <Icon name='settings' type='outlined' />
    }
  ]

  return (
    <LateralBarMenuStyled>
      <h2 covered='true'>Workeo</h2>
      {lateralBarOptions.map((option, index) => {
        const { condition, icon, action } = option
        const onHandleClick = () => {
            action()
            setAction('')
        }

        return (
          <button
            title={option.label}
            key={`btn-${index}`}
            className={condition ? 'option-selected' : ''}
            onClick={onHandleClick}
          >
            {icon}
          </button>
        )
      })}
    </LateralBarMenuStyled>
  )
}

const LateralBarMenuStyled = styled.aside`
  height: 100vh;
  width: 135px;
  min-height: 380px;
  position: relative;
  text-align: center;

  ${({ theme }) => theme.colors.primary.dark};

  display: flex;
  flex-direction: column;

  button {
    height: 82px;
    width: 100%;
    background-color: transparent;
    color: #fff;

    border: none;
    cursor: pointer;

    .icon {
      font-size: 30px;
    }

    &.option-selected {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;

        background-color: #fff;
        width: 10px;
        height: 78px;

        border-radius: 0 9px 9px 0;
      }
    }

    &:last-child {
      position: absolute;
      bottom: 0;
    }
  }
`

export default MenuBar
