import { INPUT_TYPES } from 'containers/form'
import { Validations } from 'utils/validators'

export const LINE_STATUS = {
  Proposal: 'Proposal',
  Offer: 'Offer',
  Active: 'Active',
  Frozen: 'Frozen',
  Cancelled: 'Cancelled'
}

export const LINE_FORM = [
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'amount',
    label: 'Credit Line Request *',
    placeholder: 'Example: 100',
    validations: [Validations.isMandatory, Validations.creditLineRequest]
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'cashbackPeriod',
    label: 'Grace Period (Days) *',
    placeholder: 'Example: 100',
    validations: [Validations.isMandatory, Validations.numbers]
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'termA',
    label: 'Term A *',
    placeholder: 'Example: 100',
    validations: [Validations.isMandatory, Validations.numbers]
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'termB',
    label: 'Term B (Daily Rate) *',
    placeholder: 'Example: 100',
    validations: [Validations.isMandatory, Validations.numbers]
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'cashbackRate',
    label: 'Grace Rate %',
    disabled: true,
    value: 0,
    validations: []
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'rateA',
    label: 'Rate A % (Fixed) *',
    placeholder: 'Example: 100',
    validations: [Validations.isMandatory, Validations.numbers]
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'rateB',
    label: 'Rate B % (Daily) *',
    placeholder: 'Example: 100',
    validations: [Validations.isMandatory, Validations.numbers]
  }
]
