import axios from 'axios'
import { ENDPOINTS } from './config'
import fileDownload from 'js-file-download'

/**
 *
 * @param {string} customerId
 * @returns
 */
export const getDocuments = (customerId) =>
  axios.get(ENDPOINTS.getCustomerFiles, { params: { customerId } })

/**
 *
 * @param {FormData} formData
 * @returns
 */
export const uploadDocument = (formData) =>
  axios.post(ENDPOINTS.uploadFile, formData)

/**
 *
 * @param {string} id - Document id
 * @returns
 */
export const downloadDocument = (id) =>
  `${process.env.REACT_APP_API_HOST}/api/file.download?id=${id}`


  /**
 *
 * @param {string} id - File id
 * @returns
 */
export const downloadFile = async (id, fileName) => {
  const url = `${process.env.REACT_APP_API_HOST}/api${ENDPOINTS.getFile}?id=${id}`
  await downloadUrlFile(url, fileName)
}
  
/**
 * @param {string} url Link or URL where the document is hosted
 * @param {string} filename Final name with extension of the document after downloading
 */
const downloadUrlFile = async (url, filename) => {
    const { data } = await axios.get(url, {
      responseType: 'blob'
    })
    fileDownload(data, filename)
}


