import { LoadingAnimation } from '@keoworld/gbl-ui-kit'
import {
  CustomerDetailContext,
  CUSTOMER_DETAIL_SECTIONS
} from 'providers/customer-detail'
import { useContext, useState } from 'react'
import styled from 'styled-components'
import CustomerDocuments from 'views/customer/tabs/customer-documents'
import CustomerInformation from 'views/customer/tabs/customer-information'
import Header from './header/header'
import CustomerComments from './tabs/customer-comments'
import CustomerLineConditions from './tabs/customer-line-conditions'

const Content = [
  {
    label: 'Information',
    option: CUSTOMER_DETAIL_SECTIONS.INFORMATION,
    component: CustomerInformation
  },
  {
    label: 'Documents',
    option: CUSTOMER_DETAIL_SECTIONS.DOCUMENTS,
    component: CustomerDocuments
  },
  {
    label: 'Comments',
    option: CUSTOMER_DETAIL_SECTIONS.COMMENTS,
    component: CustomerComments
  },
  {
    label: 'Line Conditions',
    option: CUSTOMER_DETAIL_SECTIONS.LINE_CONDITIONS,
    component: CustomerLineConditions
  }
]

const CustomerDetail = () => {
  const [currentTab, setCurrentTab] = useState(
    CUSTOMER_DETAIL_SECTIONS.INFORMATION
  )
  const { customer } = useContext(CustomerDetailContext)

  const Loader = () => (
    <CustomerDetailStyled>
      <div className="center-loading">
        <LoadingAnimation />
      </div>
    </CustomerDetailStyled>
  )

  const Tab = Content.find((e) => e.option === currentTab).component ?? Loader

  return (
    <CustomerDetailStyled>
      <Header
        customer={customer}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        headerOptions={Content.map((e) => ({
          label: e.label,
          option: e.option
        }))}
      />
      {Tab && <Tab setCurrentTab={setCurrentTab} />}
    </CustomerDetailStyled>
  )
}

const CustomerDetailStyled = styled.div`
  .center-loading {
    height: 60vh;
    display: flex;
    align-items: center;
    div {
      width: 150px;
    }
  }
`

export default CustomerDetail
