import axios from 'axios'
import { auth } from 'services/firebase.controller'
import { API_HOST } from 'utils/constants'

export const ENDPOINTS = {
  instances: `/workflow/instances/`,
  transition: `/workflow/instances/transition`,
  getWorkflowVersions: `/workflow/workflows/versions`,
  getCustomerInfo: `/customer.info`,
  getCustomerFiles: `/file.find`,
  getCustomers: `/customer.find`,
  updateCustomer: '/customer.update',
  getStates: `/state.find`,
  getCities: `/city.find`,
  getFile: `/file.download`,
  uploadFile: `/file.upload`,
  line: {
    find: `/line.find`,
    info: `/line.info`
  },
  comment: {
    find: `/comment.find`,
  }
}

export const setUpAxios = () =>
  axios.interceptors.request.use(
    async (config) => {
      const currentUser = auth?.currentUser
      if (currentUser) {
        config.headers.authorization = `Bearer ${await currentUser.getIdToken()}`
      } else {
        config.cancelToken = new axios.CancelToken((cancel) =>
          cancel('No user signed in')
        )
      }
      // Do something before request is sent
      config.baseURL = `${API_HOST}/api`

      return config
    },
    (error) => {
      console.log('error', error)
      // Do something with request error
      return Promise.reject(error)
    }
  )
