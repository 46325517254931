import CheckRadioButton from 'components/check-radio-button'
import styled from 'styled-components'
import InputFile from '../components/inputFile'
import { downloadFile } from '../services/documents'

/**
 * @param {object} props
 * @param {object} props.controller
 * @param {function} props.setController
 * @param {object} props.skema
 * @returns
 */
const FileForm = ({
  controller,
  setController,
  schema,
  withCheck = false,
  withUpload = false,
  deleteFile,
  isValidatingDoc
}) => {
  const handleInputChange = ({ name, fileTypeId }, files) => {
    const file = files[0]
    file.fileTypeId = fileTypeId

    setController((form) => ({
      ...form,
      [fileTypeId]: file
    }))
  }
  const onClickDelete = (fileTypeId) => {
    setController((prev) => ({
      ...prev,
      [fileTypeId]: undefined
    }))
  }

  const handleAcceptCheck = (name, { fileTypeId }, value) => {
    if (name)
      setController((form) => {
        const updatedFile = form[fileTypeId] ?? {}
        updatedFile.acceptance = value
        return {
          ...form,
          [fileTypeId]: updatedFile
        }
      })
  }

  const handleDownload = async (typeFileName) => {
    const file = controller[typeFileName]
    try {
      await downloadFile(file?.id, file?.name)
    } catch (err) {
      console.log(err?.message ?? 'Unknown error on download.')
    }
  }

  return (
    <FileFormStyled>
      {schema.map(({ label, name, fileTypeId, documentType, isRequired }, idx) => (
        <div className='file-section-actions' key={`index-${idx}`}>
          <InputFile
            key={`file-${idx}`}
            label={label}
            file={controller[fileTypeId]}
            name={name}
            allowedDocumentTypes={documentType}
            onChange={(e) => handleInputChange({ name, fileTypeId }, e)}
            onClickDownload={() => handleDownload(fileTypeId)}
            allowUpload={withUpload}
            onClickDelete={() => onClickDelete(fileTypeId)}
            deleteFile={deleteFile}
            warning={isValidatingDoc && isRequired}
          />
          {withCheck && (
            <CheckRadioButton
              value={controller[fileTypeId]?.acceptance}
              setValue={(value) =>
                handleAcceptCheck(
                  controller[fileTypeId]?.name,
                  { fileTypeId },
                  value
                )
              }
            />
          )}
        </div>
      ))}
    </FileFormStyled>
  )
}

const FileFormStyled = styled.div`
  display: flex;
  flex-wrap: wrap;

  .file-section-actions {
    display: flex;
    align-items: center;
    width: 100%;
  }
`

export const DocumentSectionStyled = styled.section`
  .section-label {
    align-items: center;
    cursor: pointer;
    display: flex;
    position: absolute;
    left: -14px;
    top: -20px;
    width: 100%;

    span {
      color: ${({ theme }) => theme.colors.primary.dark.backgroundColor};
    }

    h3 {
      padding: 0em 1em;
    }
  }

  .file-form-section {
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    padding: 1em 2em 2em 2em;
  }
`

export default FileForm
