import { Button, LoadingAnimation, Modal } from '@keoworld/gbl-ui-kit'
import { CustomerDetailProvider } from 'providers/customer-detail'
import { LATERAL_MENU_OPTIONS, MenuBarContext } from 'providers/menu-bar'
import { SessionContext } from 'providers/session-manager'
import { useContext } from 'react'
import styled from 'styled-components'
import Actions from 'views/actions'
import DetailOnBoarding from 'views/onboarding/detail-onboarding'
import CustomerDetail from 'views/customer/customer-detail'
import CustomerDashboard from 'views/customer/dashboard-customers'
import Dashboard from 'views/onboarding/dashboard-onboarding'
import CreateOnboarding from 'views/onboarding/create-onboarding'

const components = {
  [LATERAL_MENU_OPTIONS.DASHBOARD]: Dashboard,
  [LATERAL_MENU_OPTIONS.ONBOARDING_CREATE]: CreateOnboarding,
  [LATERAL_MENU_OPTIONS.ONBOARDING_DETAIL]: DetailOnBoarding,
  [LATERAL_MENU_OPTIONS.DASHBOARD_CUSTOMER]: CustomerDashboard,
  [LATERAL_MENU_OPTIONS.CUSTOMER_DETAIL]: CustomerDetail,
}

const MainTemplate = () => {
  let Component
  const { selectedOption, action } = useContext(MenuBarContext)
  const { projectIsOutdated } = useContext(SessionContext)

  const exists = Object.keys(components).find((key) => key === selectedOption)
  if (action) {
    Component = Actions
  } else if (exists) {
    Component = components[selectedOption]
  } else {
    Component = () => <LoadingAnimation className='loading-center' />
  }

  return (
    <DashboardContainerStyled>
      <CustomerDetailProvider>
        <Component />
      </CustomerDetailProvider>
        { projectIsOutdated && <ModalRefreshStyled>
            <div>
              <p>Please Refresh Caché</p>
              <Button onClick={()=>window.location.reload(true)}>Click here</Button>
            </div>
          </ModalRefreshStyled>
        }
    </DashboardContainerStyled>
  )
}

const ModalRefreshStyled = styled(Modal)`
  .card-modal {
    width: auto;
    height: auto;
    min-height: auto;
    border-radius: 21px;
    padding: 1em;
    text-align: center;
  }
` 

const DashboardContainerStyled = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 70px);

  .loading-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 300px;
  }
`

export default MainTemplate
