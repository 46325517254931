import {
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut
} from 'firebase/auth'
import { SessionContext } from 'providers/session-manager'
import { useContext } from 'react'
import { auth, microsoftProvider } from 'services/firebase.controller'

export const useAuth = () => {
  const { setUserAuth, userAuth } = useContext(SessionContext)

  /**
   * @param {string} email
   * @param {string} password
   */
  const signInWithEmail = async (email, password) => {
    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password)
      setUserAuth(user)
    } catch (error) {
      console.log('error', error)
    }
  }

  const signInWithMicrosoft = async () => {
    try {
      const { _tokenResponse } = await signInWithPopup(auth, microsoftProvider)
      window.localStorage.setItem('oauthIdToken', _tokenResponse.oauthIdToken)
    } catch (error) {
      console.log('error', error)
    }
  }

  const signOutController = () => {
    signOut(auth)
    setUserAuth()
  }

  const isAuthenticated = Boolean(userAuth)

  return {
    isAuthenticated,
    signInWithEmail,
    signInWithMicrosoft,
    signOut: signOutController,
    user: userAuth
  }
}
