import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import Comment from 'components/inputComment'
import Form, { INPUT_TYPES } from 'containers/form'
import { AlertContext } from 'providers/alert'
import { CustomerDetailContext } from 'providers/customer-detail'
import { LATERAL_MENU_OPTIONS, MenuBarContext } from 'providers/menu-bar'
import { SessionContext } from 'providers/session-manager'
import { useContext, useEffect, useState } from 'react'
import { getCustomerDetail, updateCustomer } from 'services/customer'
import { instanceTransition } from 'services/workflow'
import styled from 'styled-components'
import { CUSTOMER_STATES } from 'utils/schemas/customer'
import { Validations } from 'utils/validators'

const FORM_TAX = [
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'taxId',
    label: 'Federal Tax ID',
    placeholder: 'Example: xxxxxxxx',
    validations: [Validations.isMandatory, Validations.numbers]
  }
]

const initialForm = {
  taxId: ''
}

const SendExternalLink = () => {
  const [formValues, setFormValues] = useState(initialForm)
  const [formErrors, setFormErrors] = useState({})
  const [optionSelected, setOptionSelected] = useState(true)
  const [commentForm, setCommentForm] = useState({ comment: null })
  const [commentError, setCommentError] = useState({})
  const [loadingAnimation, setLoadingAnimation] = useState(false)
  const [isValidatingTaxId, setIsValidatingTaxId] = useState(false)
  const [isValidatingComment, setIsValidatingComment] = useState(false)
  const { setAction, setSelectedOption } = useContext(MenuBarContext)
  const { customer, workflowStatus } = useContext(CustomerDetailContext)
  const { userAuth } = useContext(SessionContext)
  const { setAlert } = useContext(AlertContext)

  useEffect(() => {
    const getCustomerData = async () => {
      try {
        const { data } = await getCustomerDetail(customer.id)
        setFormValues({ taxId: data.taxId ?? '' })
      } catch (error) {
        console.log(error)
      }
    }
    getCustomerData()
  }, [customer.id])

  const validateFormErrors = (forms) => {
    let hasErrors = true
    let hasErrorComment = true
    if (Object.entries(formErrors).length === 0) {
      hasErrors = false
    } else {
      setIsValidatingTaxId(true)
    }
    if (Object.entries(commentError).length === 0) {
      hasErrorComment = false
    } else {
      setIsValidatingComment(true)
    }
    return forms === 'all' ? !hasErrors && !hasErrorComment : !hasErrors
  }

  const updateTaxId = async (changeLoadingAnimation = true) => {
    setLoadingAnimation(true)
    const validationErrors = validateFormErrors()
    if (validationErrors) {
      try {
        Object.entries(formValues).forEach(([key, value]) => {
          if(typeof value === 'string')
            formValues[key] = value.trim()
        });
        const form = { ...formValues, id: customer.id }
        await updateCustomer(form)
        return true
      } catch (error) {
        setAlert({
          title: 'Error',
          label:
            'An error occurred while updating the customer, try again later',
          type: 'error'
        })
        return false
      } finally {
        setLoadingAnimation(false)
      }
    }
  }

  const handleTransition = async () => {
    let sendTransition = CUSTOMER_STATES.cust_prospectCreated.transition
    if (optionSelected) sendTransition = sendTransition.SEND_LINK
    else sendTransition = sendTransition.SEND_BPM
    const validationErrors = validateFormErrors('all')
    if (validationErrors) {
      try {
        const result = await updateTaxId(false)
        if (result) {
          setLoadingAnimation(true)
          await instanceTransition(
            workflowStatus?.workflowId,
            String(customer?.id),
            sendTransition.event,
            {
              id: customer?.id,
              customerId: customer?.id,
              'wfState:comment': customer?.wfState,
              comment: commentForm?.comment,
              wfStage: sendTransition.nextStage,
              userUid: userAuth.uid,
              'wfState:state': sendTransition.nextState
            }
          )
          setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD)
          setAction(false)
        }
      } catch (err) {
        setAlert({
          title: 'Error',
          label: 'An error occurred, try again later',
          type: 'error'
        })
      } finally {
        setLoadingAnimation(false)
      }
    }
  }

  return (
    <UploadDocumentsStyled>
      <h3>Onboarding Generation</h3>
      <section>
        <Form
          schema={FORM_TAX}
          formValues={formValues}
          setFormValues={setFormValues}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          isValidating={isValidatingTaxId}
        />
        <Button
          disabled={loadingAnimation}
          buttonType='grayButton'
          onClick={() => updateTaxId()}
        >
          {loadingAnimation ? (
            <LoadingAnimation className='loading' />
          ) : (
            'Update'
          )}
        </Button>
      </section>
      <section>
        <div>
          <input
            type='radio'
            value={true}
            name='isSendLink'
            checked={optionSelected === true}
            onChange={() => {
              setOptionSelected(true)
            }}
          />{' '}
          Generate Onboarding link for Customer
          <br />
          <input
            type='radio'
            value={false}
            name='isSendLink'
            checked={optionSelected === false}
            onChange={() => {
              setOptionSelected(false)
            }}
          />{' '}
          Continue Onboarding in Workeo
        </div>
      </section>
      <section>
        <div className='width-50'>
          <Comment
            formValues={commentForm}
            setFormValues={setCommentForm}
            formErrors={commentError}
            setFormErrors={setCommentError}
            isValidating={isValidatingComment}
          />
        </div>
      </section>
      <div className='action-group'>
        <Button buttonType='grayButton' onClick={() => setAction(false)}>
          Cancel
        </Button>
        <Button
          disabled={loadingAnimation}
          className='send'
          onClick={() => handleTransition()}
        >
          {loadingAnimation ? (
            <LoadingAnimation className='loading' />
          ) : (
            'Continue'
          )}
        </Button>
      </div>
    </UploadDocumentsStyled>
  )
}

const UploadDocumentsStyled = styled.section`
  padding: 2rem;
  .loading span {
    background-color: white;
  }
  section {
    padding-top: 2rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .width-50 {
      width: 49.5vw;
    }
  }

  .form-container {
    margin: 2rem 1rem;

    & > * {
      margin: 1rem;
    }
  }

  .saved-files {
    display: flex;
    flex-wrap: wrap;

    & > * {
      margin: 6px;
      overflow: hidden;
      border: solid 1px grey;
      border-radius: 1em;
      padding: 6px;
    }
  }

  .image-container {
    display: flex;
    justify-content: center;
    margin: 2rem 1rem;

    & > * {
      margin: 1rem;
    }
  }

  .image-viewer {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 40%;
  }

  .image-p {
    width: 150px;
    font-size: 12px;
    padding: 10px 5px 5px 5px;
  }

  .image-tile {
    width: 100px;
    height: 100px;
    background-color: grey;
    border-radius: 10px;
    margin: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
  }

  .icon-image {
    width: 20px;
    height: 20px;
    position: relative;
    top: 0rem;
    right: 1.5rem;
    span {
      display: flex;
      margin: auto;
      justify-content: center;
      align-items: center;
      font-size: 15px;
    }
  }

  .action-group {
    margin: 2rem 42px 0;

    button + button {
      margin-left: 28px;
    }
    .send {
      float: right;
    }
  }
`

export default SendExternalLink
