import { CustomerDetailContext } from 'providers/customer-detail'
import { Fragment, useContext } from 'react'
import styled from 'styled-components'
import { CUSTOMER_STATES } from 'utils/schemas/customer'

const InfoSummary = ({ currentRoute = [], title }) => {
  const { customer, workflowStatus } = useContext(CustomerDetailContext)
  const customerStatusInfo = CUSTOMER_STATES[workflowStatus?.currentState]

  return (
    <InfoSummaryStyled>
      <div className="router">
        <span>Home</span>
        <span bold="true"> {'>'} </span>
        <span
          className="clickable"
          // onClick={()=>setAction(false)}
        >
          Detail
        </span>
        {currentRoute.map((itm, idx) => (
          <Fragment key={`breadcumb-itm-${idx}`}>
            <span bold="true"> {'>'} </span>
            <span>{itm}</span>
          </Fragment>
        ))}
      </div>
      {title && <h2>{title}</h2>}
      <h3>{customer?.name}</h3>
      <div body1="true">ID {customer?.id}</div>
      <div body1="true">
        <span bold="true">Status:</span> {customerStatusInfo?.stateLabel}
      </div>
    </InfoSummaryStyled>
  )
}

const InfoSummaryStyled = styled.div`
  .clickable {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`

export default InfoSummary
