import { LoadingAnimation } from '@keoworld/gbl-ui-kit'
import LineCard from 'containers/line-card'
import { CustomerDetailContext } from 'providers/customer-detail'
import { useContext, useEffect, useState } from 'react'
import { getLineFind } from 'services/lines'
import styled from 'styled-components'

const CustomerLineConditions = () => {
  const [loading, setLoading] = useState(false)
  const [line, setLine] = useState(false)
  const { customer } = useContext(CustomerDetailContext)

  useEffect(() => {
    const setFirstLine = async () => {
      setLoading((prev) => ({ ...prev, initialLoad: true }))
      try {
        const { data } = await getLineFind(customer?.id)
        setLine(data[0])
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
    setFirstLine()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CustomerLineConditionStyled>
      {loading && (
        <div className="center-loading">
          <LoadingAnimation />
        </div>
      )}
      {!loading && (
        <div>
          <LineCard title={'Line Condition'} line={line} />
        </div>
      )}
    </CustomerLineConditionStyled>
  )
}

const CustomerLineConditionStyled = styled.section`
  /* padding: 1rem; */
  div {
    h3 {
      padding: 1rem 0 0.5rem 0;
    }
  }
`

export default CustomerLineConditions
