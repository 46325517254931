import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import Comment from 'components/inputComment'
import LineCard from 'containers/line-card'
import { AlertContext } from 'providers/alert'
import { CustomerDetailContext } from 'providers/customer-detail'
import { LATERAL_MENU_OPTIONS, MenuBarContext } from 'providers/menu-bar'
import ModalUpload from 'providers/modal/modal-documents-upload'
import { SessionContext } from 'providers/session-manager'
import { useContext, useEffect, useState } from 'react'
import { getLineFind } from 'services/lines'
import { instanceTransition } from 'services/workflow'
import styled from 'styled-components'
import { CUSTOMER_STATES } from 'utils/schemas/customer'

const UnderwritingResult = () => {
  const [commentForm, setCommentForm] = useState({ comment: null })
  const [commentError, setCommentError] = useState({})
  const [loadingAnimation, setLoadingAnimation] = useState(false)
  const [isValidatingComment, setIsValidatingComment] = useState(false)
  const { setAction, setSelectedOption } = useContext(MenuBarContext)
  const { customer, workflowStatus } = useContext(CustomerDetailContext)
  const { userAuth } = useContext(SessionContext)
  const [lineValue, setLineValue] = useState({})
  const { setAlert } = useContext(AlertContext)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    const setFirstLine = async () => {
      try {
        const { data } = await getLineFind(customer.id)
        setLineValue(data[0])
      } catch (error) {
        console.log(error)
      }
    }
    setFirstLine()
  }, [customer.id])

  const validateFormErrors = () => {
    let hasErrorComment = true
    if (Object.entries(commentError).length === 0) {
      hasErrorComment = false
    } else {
      setIsValidatingComment(true)
    }
    return !hasErrorComment
  }

  const AddPlatformInfo = async (line) => {
    line.platformId = 1
    line.platform = 'i2c'
    return line
  }

  const handleTransition = async () => {
    setLoadingAnimation(true)
    let sendTransition = CUSTOMER_STATES.cust_underwritingResult.transition.SEND
    const validationErrors = validateFormErrors()
    const lineUpdateSend = await AddPlatformInfo(lineValue)
    if (validationErrors) {
      try {
        await instanceTransition(
          workflowStatus?.workflowId,
          String(customer?.id),
          sendTransition.event,
          {
            ...lineUpdateSend,
            'id:state': customer?.id,
            'wfState:comment': customer?.wfState,
            comment: commentForm?.comment,
            userUid: userAuth.uid,
            customerId: customer.id,
            'wfState:state': sendTransition.nextState
          }
        )
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD)
        setAction(false)
      } catch (err) {
        setAlert({
          title: 'Error',
          label: 'An error occurred, try again later',
          type: 'error'
        })
      }
    }
    setLoadingAnimation(false)
  }

  const handleReject = async () => {
    setLoadingAnimation(true)
    setIsValidatingComment(true)
    let sendTransition =
      CUSTOMER_STATES.cust_underwritingResult.transition.REJECT
    if (commentForm.comment !== '' && commentForm.comment !== null) {
      try {
        await instanceTransition(
          workflowStatus?.workflowId,
          String(customer?.id),
          sendTransition.event,
          {
            id: customer?.id,
            customerId: customer.id,
            'wfState:comment': customer?.wfState,
            comment: commentForm?.comment,
            userUid: userAuth.uid,
            'wfState:state': sendTransition.nextState
          }
        )
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD)
        setAction(false)
      } catch (err) {
        setAlert({
          title: 'Error',
          label: 'An error occurred, try again later',
          type: 'error'
        })
      }
    } else {
      setAlert({
        title: 'Warning',
        label: 'Complete all requested fields',
        type: 'warning'
      })
    }
    setShowModal(false)
    setLoadingAnimation(false)
  }

  return (
    <>
      <UploadDocumentsStyled>
        <LineCard title={'Formal Offer'} line={lineValue} />
        <section className='section-content'>
          <h3>Platform</h3>
          <div>
            <input
              type='radio'
              value={true}
              name='isSendLink'
              checked={true}
              onChange={() => {}}
            />
            i2c
          </div>
        </section>
        <section>
          <div className='section-content col-12'>
            <Comment
              formValues={commentForm}
              setFormValues={setCommentForm}
              formErrors={commentError}
              setFormErrors={setCommentError}
              isValidating={isValidatingComment}
            />
          </div>
        </section>
        <div className='action-group'>
          <Button buttonType='grayButton' onClick={() => setAction(false)}>
            Cancel
          </Button>
          <Button
            disabled={loadingAnimation}
            className='send'
            onClick={() => handleTransition()}
          >
            {loadingAnimation ? <LoadingAnimation /> : 'Send'}
          </Button>
          <Button
            disabled={loadingAnimation}
            className='send'
            buttonType='grayButton'
            onClick={() => setShowModal(true)}
          >
            {loadingAnimation ? <LoadingAnimation /> : 'Close Prospect'}
          </Button>
        </div>
      </UploadDocumentsStyled>
      {showModal && (
        <ModalUpload
          setOpenModalUpload={setShowModal}
          messages={{
            msg: 'Are you sure you want to close the prospect? If you close the prospect you will not be able to resume the process',
            msgButton: 'Accept'
          }}
          handleSave={handleReject}
          isLoadingSubmit={loadingAnimation}
        />
      )}
    </>
  )
}

const UploadDocumentsStyled = styled.section`
  padding: 2rem;

  .col-12 {
    grid-template-columns: 1fr !important;
  }

  .section-content {
    margin: 2rem 42px 0;
    div {
      margin-top: 1rem;
    }
  }

  .action-group {
    margin: 2rem 42px 0;

    button + button {
      margin-left: 28px;
    }
    .send {
      float: right;
    }
  }
`

export default UnderwritingResult
