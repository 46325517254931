export const camelCase = (string) => {
  return string.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => {
    return str.toUpperCase()
  })
}

export const startCase = (string) => {
  const result = string.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}
