import { Icon } from '@keoworld/gbl-ui-kit'
import { INPUT_TYPES } from 'containers/form'
import FormErrorHandler from 'containers/formErrorHandler'
import { useState } from 'react'
import styled from 'styled-components'
import { CUSTOMER_FORM_SCHEMAS } from 'utils/schemas/customer'
import OwnerForm from './owner-form'

const FORM_SECTIONS = [
  {
    id: 'customerProfile',
    label: 'Customer Profile',
    schema: CUSTOMER_FORM_SCHEMAS.FORM_INFORMATION
  },
  {
    id: 'contact',
    label: 'Contact',
    schema: CUSTOMER_FORM_SCHEMAS.FORM_CONTACT
  },
  {
    id: 'owners',
    label: 'Business Owners',
    schema: CUSTOMER_FORM_SCHEMAS.FORM_ADD_OWNER
  },
  {
    id: 'financial',
    label: 'Financial Information',
    schema: CUSTOMER_FORM_SCHEMAS.FORM_FINANCIAL_INFORMATION
  },
  {
    id: 'loanRequest',
    label: 'Loan Request',
    schema: CUSTOMER_FORM_SCHEMAS.FORM_LOAN_REQUEST
  }
]

const CustomerForm = ({
  formValues,
  setFormValues,
  isValidating,
  formErrors,
  info = false
}) => {
  const [openedSections, setOpenedSections] = useState(
    FORM_SECTIONS.reduce((acc, itm) => {
      return { ...acc, [itm.id]: itm.id === 'customerProfile' || itm.id === 'contact'}
    }, {})
  )

  if (info) {
    FORM_SECTIONS[0].schema = [
      ...CUSTOMER_FORM_SCHEMAS.FORM_INFORMATION,
      {
        inputType: INPUT_TYPES.INPUT,
        name: 'taxId',
        label: 'taxId',
        disabled: true
      }
    ]
  } else {
    FORM_SECTIONS[0].schema = CUSTOMER_FORM_SCHEMAS.FORM_INFORMATION
  }

  const handleToggleSection = (section) => {
    setOpenedSections((prev) => ({ ...prev, [section]: !prev[section] }))
  }

  return (
    <CustomerUpdateStyled>
      {FORM_SECTIONS.map((section, idx) => (
        <CustomerUpdateSection
          isOpen={openedSections[section?.id]}
          key={`cust-form-${idx}`}
        >
          <div
            className='section-label'
            onClick={() => handleToggleSection(section?.id)}
          >
            <Icon
              name={
                openedSections[section?.id] ? 'radio_button_checked' : 'circle'
              }
            />
            <h3>{section?.label}</h3>
          </div>
          {section.id === 'owners' ? (
            <OwnerForm
              schema={section?.schema}
              formValues={formValues}
              setFormValues={setFormValues}
              isValidating={isValidating}
            />
          ) : (
            <div className='section-content collapsable'>
              <FormErrorHandler
                schema={section?.schema}
                formValues={formValues}
                setFormValues={setFormValues}
                formErrors={formErrors}
              />
            </div>
          )}
        </CustomerUpdateSection>
      ))}
    </CustomerUpdateStyled>
  )
}

const CustomerUpdateStyled = styled.div`
  border-left: solid 2px
    ${({ theme }) => theme.colors.primary.dark.backgroundColor};
  padding: 1em 1em 0 1em;
  margin: 1em 0;
`

const CustomerUpdateSection = styled.section`
  padding-bottom: 2em;
  margin-bottom: 1em;

  .section-label {
    margin-top: -1.5em;
    margin-left: -27px;
    display: flex;
    flex-direction: row;
    cursor: pointer;

    span {
      color: ${({ theme }) => theme.colors.primary.dark.backgroundColor};
    }
  }

  .collapsable {
    display: ${({ isOpen }) => (isOpen ? 'grid' : 'none')} !important;
  }

  .section-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    & > div {
      padding: 0 1rem;
      &:nth-child(3n + 1) {
        padding-left: 0;
      }
      &:nth-child(3n) {
        padding-right: 0;
      }
    }

    .textarea {
      grid-column: span 2;
    }

    @media (max-width: 780px) {
      grid-template-columns: 1fr 1fr;
      & > div {
        padding: 0 1rem;
        &:nth-child(2n + 1) {
          padding-left: 0;
        }
        &:nth-child(2n) {
          padding-right: 0;
        }
      }
    }
  }
`

export default CustomerForm
