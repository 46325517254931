import { Button, Icon, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import Comment from 'components/inputComment'
import LineCard from 'containers/line-card'
import { AlertContext } from 'providers/alert'
import { CustomerDetailContext } from 'providers/customer-detail'
import { LATERAL_MENU_OPTIONS, MenuBarContext } from 'providers/menu-bar'
import { SessionContext } from 'providers/session-manager'
import ModalUpload from 'providers/modal/modal-documents-upload'
import { useContext, useEffect, useState } from 'react'
import { getLineFind } from 'services/lines'
import { instanceTransition } from 'services/workflow'
import styled from 'styled-components'
import { CUSTOMER_STATES } from 'utils/schemas/customer'
import { LINE_STATUS } from 'utils/schemas/line'

const CustomerApproval = () => {
  const [commentForm, setCommentForm] = useState({ comment: null })
  const [commentError, setCommentError] = useState({})
  const [loadingAnimation, setLoadingAnimation] = useState(false)
  const [isValidatingComment, setIsValidatingComment] = useState(false)
  const { setAction, setSelectedOption } = useContext(MenuBarContext)
  const { customer, workflowStatus } = useContext(CustomerDetailContext)
  const { userAuth } = useContext(SessionContext)
  const [lineValue, setLineValue] = useState({})
  const [isApproveOffer, setIsApproveOffer] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const { setAlert } = useContext(AlertContext)

  useEffect(() => {
    const setFirstLine = async () => {
      try {
        const { data } = await getLineFind(customer.id)
        setLineValue(data[0])
      } catch (error) {
        console.log(error)
      }
    }
    setFirstLine()
  }, [customer.id])

  const validateFormErrors = () => {
    let hasErrorComment = true
    if (Object.entries(commentError).length === 0) {
      hasErrorComment = false
    } else {
      setIsValidatingComment(true)
    }
    return !hasErrorComment
  }

  const handleTransition = async () => {
    setLoadingAnimation(true)

    const sendTransition = isApproveOffer
      ? CUSTOMER_STATES.cust_customerApproval.transition.SEND
      : CUSTOMER_STATES.cust_customerApproval.transition.REJECT

    if (!isApproveOffer) lineValue.status = LINE_STATUS.Proposal
    const validationErrors = validateFormErrors()
    try {
      if (validationErrors) {
        await instanceTransition(
          workflowStatus?.workflowId,
          String(customer?.id),
          sendTransition.event,
          {
            ...lineValue,
            'id:state': customer?.id,
            'wfState:comment': customer?.wfState,
            comment: commentForm?.comment,
            userUid: userAuth.uid,
            customerId: customer.id,
            'wfState:state': sendTransition.nextState
          }
        )
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD)
        setAction(false)
      }
    } catch (err) {
      setAlert({
        title: 'Error',
        label: 'An error occurred, try again later ' + err,
        type: 'error'
      })
    } finally {
      setLoadingAnimation(false)
    }
  }

  return (
    <>
      <UploadDocumentsStyled>
        <LineCard title={'Formal Offer'} line={lineValue} />
        <section className="section-radio">
          <div>
            <span>
              <input
                type="radio"
                value={true}
                name="isApprove"
                checked={isApproveOffer === true}
                onChange={() => {
                  setIsApproveOffer(true)
                }}
              />
              <Icon type="outlined" name="done" id="color-green" />
              Accepted
            </span>
            <span>
              <input
                type="radio"
                value={false}
                name="isApprove"
                checked={isApproveOffer === false}
                onChange={() => {
                  setIsApproveOffer(false)
                }}
              />
              <Icon type="outlined" name="close" id="color-red" />
              Rejected
            </span>
          </div>
        </section>
        <section>
          <div className="section-content col-12">
            <Comment
              formValues={commentForm}
              setFormValues={setCommentForm}
              formErrors={commentError}
              setFormErrors={setCommentError}
              isValidating={isValidatingComment}
            />
          </div>
        </section>
        <div className="action-group">
          <Button buttonType="grayButton" onClick={() => setAction(false)}>
            Cancel
          </Button>
          <Button
            disabled={loadingAnimation}
            className="send"
            onClick={() =>
              isApproveOffer ? handleTransition() : setShowModal(true)
            }
          >
            {loadingAnimation ? (
              <LoadingAnimation className="loading" />
            ) : (
              'Proposal Send'
            )}
          </Button>
        </div>
      </UploadDocumentsStyled>
      {showModal && (
        <ModalUpload
          setOpenModalUpload={setShowModal}
          messages={{
            msg: 'Are you sure you want to REJECT this offer?',
            msgButton: 'YES, reject it',
            msgButtonCancel: 'NO, accept it'
          }}
          handleSave={handleTransition}
          isLoadingSubmit={loadingAnimation}
        />
      )}
    </>
  )
}

const UploadDocumentsStyled = styled.section`
  padding: 2rem;

  .col-12 {
    grid-template-columns: 1fr !important;
  }
  .loading span {
    background-color: white;
  }
  .section-radio {
    margin: 2rem 42px 0;
    div {
      margin-top: 1rem;
    }
    input[type='radio'] {
      height: 1.2em;
      width: 1.2em;
      /* vertical-align: middle; */
    }
    span {
      display: flex;
      margin-top: 0.5rem;
    }
    #color-red {
      color: red;
      margin: 0 0.4rem;
      font-size: 1.3rem;
    }
    #color-green {
      color: green;
      margin: 0 0.4rem;
      font-size: 1.3rem;
    }
  }

  .section-content {
    margin: 2rem 42px 0;
  }

  .action-group {
    margin: 2rem 42px 0;

    button + button {
      margin-left: 28px;
    }
    .send {
      float: right;
    }
  }
`

export default CustomerApproval
