import axios from 'axios'
import { ENDPOINTS } from './config'

/**
 *
 * @param {string} customerId
 * @returns
 */
export const getCommentFind = async (customerId, query) => {
  const { page, limit } = query
  return axios.get(ENDPOINTS.comment.find, {
    params: { customerId, page: page, size: limit }
  })
}
