import { LoadingAnimation } from '@keoworld/gbl-ui-kit'
import {
  CustomerDetailContext,
  CUSTOMER_DETAIL_SECTIONS
} from 'providers/customer-detail'
import { useEffect } from 'react'
import { useContext, useRef } from 'react'
import styled from 'styled-components'
import CustomerDocuments from 'views/customer/tabs/customer-documents'
import CustomerInformation from 'views/customer/tabs/customer-information'
import DetailHeader from 'views/onboarding/header/detail-header'
import CustomerComments from '../customer/tabs/customer-comments'

const Content = {
  [CUSTOMER_DETAIL_SECTIONS.INFORMATION]: CustomerInformation,
  [CUSTOMER_DETAIL_SECTIONS.DOCUMENTS]: CustomerDocuments,
  [CUSTOMER_DETAIL_SECTIONS.COMMENTS]: CustomerComments
}

const DetailOnBoarding = () => {
  const { currentSection } = useContext(CustomerDetailContext)
  const toResetErrorRef = useRef(null)

  const Loader = () => (
    <CustomerDetailStyled>
      <div className="center-loading">
        <LoadingAnimation />
      </div>
    </CustomerDetailStyled>
  )

  const Component = Content[currentSection] ?? Loader

  useEffect(() => {
    if (toResetErrorRef) {
      toResetErrorRef.current.scrollIntoView()
    }
  }, [])

  return (
    <CustomerDetailStyled>
      <div ref={toResetErrorRef}>
        <DetailHeader />
        <Component />
      </div>
    </CustomerDetailStyled>
  )
}

const CustomerDetailStyled = styled.div`
  .center-loading {
    height: 60vh;
    display: flex;
    align-items: center;
    div {
      width: 150px;
    }
  }
`

export default DetailOnBoarding
