import { createContext, useState } from 'react'

export const LATERAL_MENU_OPTIONS = {
  DASHBOARD: 'onBoardingDashboard',
  ONBOARDING_CREATE: 'onBoardingCreate',
  ONBOARDING_DETAIL: 'onBoardingDetail',
  DASHBOARD_CUSTOMER: 'customerDashboard',
  CUSTOMER_DETAIL: 'customerDetail',
  HELP: 'help'
}

const context = {
  selectedOption: LATERAL_MENU_OPTIONS.DASHBOARD,
  action: false,
  setSelectedOption: undefined,
  setAction: undefined
}

export const MenuBarContext = createContext(context)

export const MenuBarProvider = ({ children }) => {
  const [selectedOption, setSelectedOption] = useState(context.selectedOption)
  const [action, setAction] = useState(context.action)

  return (
    <MenuBarContext.Provider
      value={{ selectedOption, setSelectedOption, action, setAction }}
    >
      {children}
    </MenuBarContext.Provider>
  )
}
