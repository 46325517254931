import { Icon } from '@keoworld/gbl-ui-kit'
import { useState } from 'react'
import styled from 'styled-components'
import { useAuth } from 'utils/hooks/auth'

const AppBar = () => {
  const { signOut, user } = useAuth()
  const [openDropdown, setOpenDropdown] = useState(false)

  const email = user?.email
  const profile = email && email.substring(0, 2)

  return (
    <AppBarStyled className='app-bar'>
      <div className='profile-picture'>{profile}</div>
      <div
        className='more-options'
        onClick={() => setOpenDropdown((prev) => !prev)}
      >
        {email}
        <Icon name='expand_more' />
        {openDropdown && (
          <button className='dropdown' onClick={signOut}>
            Sign Out
          </button>
        )}
      </div>
    </AppBarStyled>
  )
}

const AppBarStyled = styled.header`
  width: 100%;
  height: 70px;
  position: relative;
  justify-content: end;
  padding-right: 2rem;

  ${({ theme }) => theme.colors.grayShades[700]};
  display: flex;
  align-items: center;
  font-weight: 500;

  .profile-picture {
    border: 2px solid white;
    border-radius: 50px;

    margin-right: 0.5rem;
    margin-left: 1rem;

    text-transform: uppercase;
    width: 40px;
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .more-options {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  button {
    color: ${({ theme }) => theme.colors.grayShades[700].color};
    cursor: pointer;
    border: none;
    background-color: transparent;

    &.dropdown {
      position: absolute;
      width: 80%;
      height: 40px;

      border-radius: 50px;
      right: 0;
      bottom: -50px;
      z-index: 5;

      ${({ theme }) => theme.colors.grayShades[800]};

      text-align: center;
    }

    &.notifications {
      padding: 1rem;
      font-size: 16px;
      position: absolute;
      right: 1rem;
      background-color: transparent;
    }
  }
`

export default AppBar
