import styled from 'styled-components'
import CustomerRow from './customer-row'

const CustomerList = ({ customers }) => {
  return (
    <CustomerListStyled>
      <thead>
        <tr>
          <th />
          <th>Business Name</th>
          <th>DBA</th>
          <th>Business Type</th>
          <th>Status</th>
          <th>Updated At</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {customers?.length === 0 && (
          <tr>
            <td colSpan={5}>No customers found</td>
          </tr>
        )}
        {customers?.length > 0 &&
          customers.map((customer, index) => (
            <CustomerRow key={`customer-${index}`} customer={customer} />
          ))}
      </tbody>
    </CustomerListStyled>
  )
}

const CustomerListStyled = styled.table`
  padding: 0 5rem 1rem 5rem;
  width: 100%;
  text-align: center;

  tbody > tr {
    height: 70px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      height: 1px;
      width: 100%;
      bottom: 0;
      left: 0;
      background-color: ${({ theme }) =>
        theme.colors.grayShades[400].backgroundColor};
    }
  }
`

export default CustomerList
