import { Button } from '@keoworld/gbl-ui-kit'
import { CustomerDetailContext } from 'providers/customer-detail'
import { LATERAL_MENU_OPTIONS, MenuBarContext } from 'providers/menu-bar'
import { useContext } from 'react'
import styled from 'styled-components'
import { dateFormatFromIso } from 'utils/functions/date'
import { CUSTOMER_STATES } from 'utils/schemas/customer'

const NUMBER_LETTERS_PROFILE = 3

const CustomerRow = ({ customer }) => {
  const { setSelectedOption } = useContext(MenuBarContext)
  const { setCustomer } = useContext(CustomerDetailContext)

  const profile = customer?.name
    .split(' ')
    .map((s, index) => index < NUMBER_LETTERS_PROFILE && s.charAt(0))

  return (
    <CustomerRowStyled>
      <td>
        <div className="profile-picture">{profile}</div>
      </td>
      <td>{customer?.name}</td>
      <td>{customer?.dba}</td>
      <td className="large-text">{customer?.businessType}</td>
      <td>
        {
          CUSTOMER_STATES[customer?.wfState ?? 'cust_prospectCreated']
            ?.stateLabel
        }
      </td>
      <td>
        {customer.updatedAt ? dateFormatFromIso(customer.updatedAt) : '-'}
      </td>
      <td>
        <Button
          onClick={() => {
            setCustomer(customer)
            setSelectedOption(LATERAL_MENU_OPTIONS.ONBOARDING_DETAIL)
          }}
        >
          Continue
        </Button>
      </td>
    </CustomerRowStyled>
  )
}

const CustomerRowStyled = styled.tr`
  .profile-picture {
    border: 2px solid
      ${({ theme }) => theme.colors.primary.dark.backgroundColor};

    border-radius: 50px;
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-transform: uppercase;
  }

  .icon {
    opacity: 1;
    &.material-icons-outlined {
      opacity: 0.5;
    }
  }

  td {
    text-transform: capitalize;

    .large-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
      padding: 0 20px;
    }
  }

  .traffic-light {
    .circle {
      border-radius: 50px;
      height: 30px;
      line-height: 30px;
      max-width: 120px;
      margin: auto;

      padding: 0 10px;

      text-align: center;

      &.available {
        ${({ theme }) => theme.colors.otherOptions.light};
      }
      &.warning {
        ${({ theme }) => theme.colors.otherOptions.main};
      }
      &.risk {
        ${({ theme }) => theme.colors.primary.main};
      }
      &.disabled {
        ${({ theme }) => theme.colors.grayShades[500]};
      }
    }
  }
`

export default CustomerRow
