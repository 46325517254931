import styled from 'styled-components'
import LineForm from 'containers/line/line-form'
import { useContext, useEffect, useState } from 'react'
import { CustomerDetailContext } from 'providers/customer-detail'
import { getLineFind } from 'services/lines'
import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import UploadDocumentsOnly from '../../containers/upload-documents'
import Comment from 'components/inputComment'
import { LATERAL_MENU_OPTIONS, MenuBarContext } from 'providers/menu-bar'
import { CUSTOMER_STATES } from 'utils/schemas/customer'
import {
  FILE_FORM_SCHEMAS,
  FILE_STAGES,
  FILE_STATES
} from 'utils/schemas/documents'
import { objectToFormData } from 'utils/functions/form-data'
import { uploadDocument } from 'services/documents'
import { instanceTransition } from 'services/workflow'
import LineCard from 'containers/line-card'
import { LINE_STATUS } from 'utils/schemas/line'
import { SessionContext } from 'providers/session-manager'
import ModalUpload from 'providers/modal/modal-documents-upload'
import { AlertContext } from 'providers/alert'
import LineRangeViewer from 'containers/line-range-viewer'
import { convertObjectValuesToString } from 'utils/functions/object-values-toString'

const CreditAnalysis = ({ currentSection }) => {
  const [initialLineValue, setInitialLineValue] = useState({})
  const [lineFormValues, setLineFormValues] = useState({})
  const [lineFormError, setLineFormError] = useState({})
  const { customer, workflowStatus, setCustomer } = useContext(
    CustomerDetailContext
  )
  const { userAuth } = useContext(SessionContext)
  const [commentForm, setCommentForm] = useState({ comment: null })
  const [commentError, setCommentError] = useState({})
  const { setAction, setSelectedOption } = useContext(MenuBarContext)
  const [loading, setLoading] = useState({
    initialLoad: false,
    fetchComment: false,
    fetchForm: false,
    submit: false
  })
  const [files, setFiles] = useState({})
  const [additionalFiles, setAdditionalFiles] = useState([])
  const { setAlert } = useContext(AlertContext)
  const [showModal, setShowModal] = useState(false)
  const [isValidatingDoc, setIsValidatingDoc] = useState(false)
  const customerStateInfo = CUSTOMER_STATES.cust_underwritingAnalysis

  useEffect(() => {
    const setFirstLine = async () => {
      setLoading((prev) => ({ ...prev, initialLoad: true }))
      try {
        const { data } = await getLineFind(customer?.id)
        const objectFormated = await convertObjectValuesToString(data[0])
        setInitialLineValue(objectFormated)
        setLineFormValues((prev) => ({
          ...prev,
          'id:lineUpdate': data[0]?.id,
          ...objectFormated
        }))
      } catch (error) {
        console.log(error)
      }
      setLoading((prev) => ({ ...prev, initialLoad: false }))
    }
    setFirstLine()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async () => {
    setLoading((prev) => ({ ...prev, submit: true }))
    try {
      await uploadFileList(
        Object.values(files),
        customer,
        customerStateInfo?.stage
      )
      await uploadFileList(additionalFiles, customer, customerStateInfo?.stage)
    } catch (error) {
      setAlert({
        title: 'Error',
        label: 'An error occurred, try again later',
        type: 'error'
      })
    }
    setAction(false)
    setLoading((prev) => ({ ...prev, submit: false }))
  }

  const validateFormErrors = (onlyComment = false) => {
    let hasErrorLine = false
    let hasErrorComment = false

    const error = FILE_FORM_SCHEMAS[currentSection].find(
      ({ fileTypeId }) => !files[fileTypeId]
    )

    if (error) {
      return (hasErrorComment = false)
    }

    if (Object.entries(lineFormError).length === 0) {
      hasErrorLine = true
    }
    if (Object.entries(commentError).length === 0) {
      hasErrorComment = true
    }
    return onlyComment ? hasErrorComment : hasErrorComment && hasErrorLine
  }

  const handleTransition = async () => {
    setIsValidatingDoc(true)
    setLoading((prev) => ({ ...prev, fetchComment: true, fetchForm: true }))
    const sendTransition = customerStateInfo?.transition.SEND
    const isValid = validateFormErrors()
    const lineFormSend = {}
    Object.entries(lineFormValues).forEach((element) => {
      lineFormSend[element[0]] = Number(element[1])
    })
    lineFormSend.status = LINE_STATUS.Offer

    delete lineFormSend['createdAt']
    delete lineFormSend['id']
    delete lineFormSend['platform']
    delete lineFormSend['platformId']
    delete lineFormSend['updatedAt']

    if (isValid) {
      try {
        setLoading((prev) => ({ ...prev, submit: true }))
        await uploadFileList(
          Object.values(files),
          customer,
          customerStateInfo?.stage
        )
        await uploadFileList(
          additionalFiles,
          customer,
          customerStateInfo?.stage
        )
        await instanceTransition(
          workflowStatus?.workflowId,
          String(customer?.id),
          sendTransition.event,
          {
            ...lineFormSend,
            'id:state': customer?.id,
            wfStage: sendTransition.nextStage,
            'wfState:state': sendTransition.nextState,
            comment: commentForm?.comment,
            userUid: userAuth.uid,
            'wfState:comment': customer?.wfState
          }
        )
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD)
        setCustomer({})
        setAction(false)
      } catch (err) {
        setAlert({
          title: 'Error',
          label: 'An error occurred, try again later',
          type: 'error'
        })
      } finally {
        setLoading((prev) => ({ ...prev, submit: false }))
      }
    }
  }

  const handleReject = async () => {
    setLoading((prev) => ({ ...prev, submit: true }))
    setLoading((prev) => ({ ...prev, fetchComment: true, fetchForm: false }))
    let sendTransition = customerStateInfo?.transition.REJECT
    if (commentForm.comment !== '' && commentForm.comment !== null) {
      try {
        await instanceTransition(
          workflowStatus?.workflowId,
          String(customer?.id),
          sendTransition.event,
          {
            id: customer?.id,
            customerId: customer.id,
            'wfState:comment': customer?.wfState,
            comment: commentForm?.comment,
            userUid: userAuth.uid,
            'wfState:state': sendTransition.nextState
          }
        )
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD)
        setAction(false)
      } catch (err) {
        setAlert({
          title: 'Error',
          label: 'An error occurred, try again later',
          type: 'error'
        })
      }
    } else {
      setAlert({
        title: 'Warning',
        label: 'Complete all requested fields',
        type: 'warning'
      })
    }
    setShowModal(false)
    setLoading((prev) => ({ ...prev, submit: false }))
  }

  return (
    <>
      {loading.initialLoad && (
        <CreditAnalysisStyled>
          <div className="center-loading">
            <LoadingAnimation />
          </div>
        </CreditAnalysisStyled>
      )}
      {!loading.initialLoad && (
        <>
          <CreditAnalysisStyled>
            <LineCard title={'Proposal'} line={initialLineValue} />
            <UploadDocumentsOnly
              additionalFiles={additionalFiles}
              setAdditionalFiles={setAdditionalFiles}
              currentSection={currentSection}
              files={files}
              setFiles={setFiles}
              isValidatingDoc={isValidatingDoc}
            />
            <LineForm
              title={'Formal Offer'}
              formErrors={lineFormError}
              setFormErrors={setLineFormError}
              formValues={lineFormValues}
              setFormValues={setLineFormValues}
              isValidating={loading.fetchForm}
            />
            <LineRangeViewer
              title={'Range Viewer'}
              margin={'1rem 1.8rem 1rem'}
              line={lineFormValues}
            />
            <section className="comments-section">
              <Comment
                formValues={commentForm}
                setFormValues={setCommentForm}
                formErrors={commentError}
                setFormErrors={setCommentError}
                isValidating={loading.fetchComment}
              />
            </section>
            <div className="action-group">
              <Button buttonType="grayButton" onClick={() => setAction(false)}>
                Close
              </Button>
              <Button disabled={loading?.submit} onClick={() => handleSubmit()}>
                Save
              </Button>
              <Button
                buttonType="green"
                className="send"
                disabled={loading?.submit}
                onClick={() => handleTransition()}
              >
                {loading?.submit ? <LoadingAnimation /> : 'Send'}
              </Button>
              <Button
                className="send"
                disabled={loading?.submit}
                buttonType="grayButton"
                onClick={() => setShowModal(true)}
              >
                {loading?.submit ? <LoadingAnimation /> : 'Close Prospect'}
              </Button>
            </div>
          </CreditAnalysisStyled>
          {showModal && (
            <ModalUpload
              setOpenModalUpload={setShowModal}
              messages={{
                msg: 'Are you sure you want to close the prospect? If you close the prospect you will not be able to resume the process',
                msgButton: 'Accept'
              }}
              handleSave={handleReject}
              isLoadingSubmit={loading?.submit}
            />
          )}
        </>
      )}
    </>
  )
}

/**
 *
 * @param {object} formFileList
 * @param {object} customer
 */
const uploadFileList = async (
  formFileList,
  customer,
  stage = FILE_STAGES.OnBoarding
) => {
  try {
    await Promise.all(
      formFileList.map(async (file) => {
        /* Upload file */
        if (file && file instanceof File) {
          const fileData = {
            file,
            name: file.name,
            extension: file.type,
            customerId: customer?.id,
            stage,
            type: file?.fileTypeId,
            status:
              file?.acceptance === 'accept'
                ? FILE_STATES.APPROVED
                : file?.acceptance === 'reject'
                ? FILE_STATES.REJECTED
                : FILE_STATES.PENDING
          }
          const formData = objectToFormData(fileData)
          return await uploadDocument(formData)
        }
        return null
      })
    )
  } catch (err) {
    console.log(err)
  }
}

const CreditAnalysisStyled = styled.div`
  padding: 2rem;
  .center-loading {
    height: 50vh;
    display: flex;
    align-items: center;
    div {
      width: 150px;
    }
  }
  .margin-2 {
    margin: 1rem 2rem 1rem;
  }
  .action-group {
    margin: 2rem 42px 0;

    button + button {
      margin-left: 28px;
    }
    .send {
      float: right;
    }
  }
  .comments-section {
    margin: 2rem 2rem 1rem;
  }
`

export default CreditAnalysis
