import axios from 'axios'
import { ENDPOINTS } from './config'
import { WORKFLOW_PROJECT_ID, WORKFLOW_CUSTOMER_ALIAS } from 'utils/constants'

const headers = () => ({
  'x-auth-workflow': localStorage.getItem('oauthIdToken')
})

/**
 *
 * @param {string} customerId
 * @returns
 */
export const getCustomerInstance = async (customerId) =>
  axios.get(ENDPOINTS.instances, {
    headers: headers(),
    params: {
      projectId: WORKFLOW_PROJECT_ID,
      instanceLocation: 'customers',
      principalId: customerId
    }
  })

/**
 *
 * @returns
 */
export const getProjectWorkflows = async () =>
  axios.get(ENDPOINTS.getWorkflowVersions, {
    headers: headers(),
    params: { alias: WORKFLOW_CUSTOMER_ALIAS }
  })

/**
 *
 * @param {string} workflowId
 * @param {object} form
 */
export const createCustomer = (workflowId, form) => {
  const body = buildCreateInstanceBody({ workflowId, requestBody: { ...form } })
  return axios.post(ENDPOINTS.instances, body, { headers: headers() })
}

/**
 *
 * @param {string} workflowId
 * @param {string} principalId
 * @param {string} event
 * @param {object} requestBody
 * @returns
 */
export const instanceTransition = (workflowId, principalId, event, requestBody) => {
  const body = buildTransitionBody({
    workflowId,
    principalId,
    event,
    requestBody })
  return axios.post(ENDPOINTS.transition, body, { headers: headers() })
}

/**
 *
 * @param {string} workflowId
 * @param {string} principalId
 * @param {object} requestBody
 * @returns {object}
 */
const buildCreateInstanceBody = ({ workflowId, principalId, requestBody }) => ({
  workflowId,
  principalId,
  context: requestBody
})

/**
 *
 * @param {string} event
 * @param {object} requestBody
 * @returns
 */
 const buildTransitionBody = ({ workflowId, principalId, event, requestBody}) => ({
  workflowId,
  principalId,
  event,
  context: requestBody
});