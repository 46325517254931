const { Outlet, Navigate } = require('react-router-dom')
const { useAuth } = require('utils/hooks/auth')

const ProtectedRoute = () => {
  const { isAuthenticated } = useAuth()

  if (isAuthenticated) {
    return <Outlet />
  }

  return <Navigate to='/sign-in' />
}

export default ProtectedRoute
