import { Button, Icon, LoadingAnimation, Modal } from '@keoworld/gbl-ui-kit'
import styled from 'styled-components'

const ModalUpload = ({
  setOpenModalUpload,
  isLoadingSubmit,
  handleSave,
  messages
}) => {
  return (
    <ModalUploadStyled>
      <Icon
        name="close"
        onClick={() => {
          setOpenModalUpload(false)
        }}
      />
      <div className="modal-content-one">
        <h4>Confirm</h4>
        <p>{messages?.msg ? messages.msg : 'Are you sure you want to save?'}</p>
        <div className="button-section">
          <Button
            disabled={isLoadingSubmit}
            onClick={() => {
              setOpenModalUpload(false)
            }}
            buttonType="grayButton"
          >
            {messages?.msgButtonCancel ? messages.msgButtonCancel : 'Cancel'}
          </Button>
          <Button disabled={isLoadingSubmit} onClick={handleSave}>
            {isLoadingSubmit ? (
              <LoadingAnimation className="loading" />
            ) : messages?.msgButton ? (
              messages.msgButton
            ) : (
              'Save'
            )}
          </Button>
        </div>
      </div>
    </ModalUploadStyled>
  )
}

const ModalUploadStyled = styled(Modal)`
  .icon {
    cursor: pointer;
    float: right;
    margin: 1rem;
  }
  .loading span {
    background-color: white;
  }

  h4 {
    font-size: 20px;
    font-weight: bold;
  }
  p {
    width: 339px;
    margin: 1rem auto;
  }
  .button-section {
    button {
      margin: auto 1rem;
    }
  }
  .card-modal {
    width: auto;
    height: auto;
    min-height: auto;
    border-radius: 21px;
    .modal-content-one {
      width: 500px;
      height: 200px;
      text-align: center;
      margin-top: 4rem;
    }
  }
  Button {
    div {
      span {
        color: white !important;
        background-color: white;
      }
    }
  }
`

export default ModalUpload
