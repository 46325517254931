import { mockFetch } from 'mocks'
import { CUSTOMERS } from 'mocks/customers'
import axios from 'axios'
import { ENDPOINTS } from './config'

/**
 * @param {Object} param
 * @param {string} param.stage
 * @param {string} param.businessName
 * @param {string} param.dba
 * @param {string} param.businessType
 * @param {string} param.legalEntity
 * @returns
 */
export const getCustomers = (filter, query) => {
  const { status, stage, businessName, dba, businessType, legalEntity } = filter
  const { page, size } = query
  const params = {
    wfStage: stage,
    wfState: status,
    name: businessName,
    dba,
    businessType,
    legalEntity,
    page,
    size
  }
  return axios.get(ENDPOINTS.getCustomers, { params })
}

/**
 *
 * @param {string} customerId
 * @returns
 */
export const getCustomer = async (customerId) => {
  const customer = CUSTOMERS.find((c) => c.id === customerId)
  return mockFetch(customer)
}

/**
 *
 * @param {string} customerId
 * @returns
 */
export const getCustomerDetail = (customerId) =>
  axios.get(ENDPOINTS.getCustomerInfo, { params: { id: customerId } })

/**
 *
 * @param {object} form
 * @returns
 */
export const updateCustomer = (form) =>
  axios.patch(ENDPOINTS.updateCustomer, form)

export const getStates = () => axios.get(`${ENDPOINTS.getStates}`)

export const getCities = (state) =>
  axios.get(`${ENDPOINTS.getCities}`, { params: { code: state } })
