import AppBar from 'containers/app-bar'
import LateralBarMenu from 'containers/dashboard/menu-bar'
import { MenuBarProvider } from 'providers/menu-bar'
import React from 'react'
import styled from 'styled-components'
import MainTemplate from 'views'

const Main = () => {
  return (
    <MenuBarProvider>
      <MainStyled>
        <LateralBarMenu />
        <div className='content'>
          <AppBar />
          <MainTemplate />
        </div>
      </MainStyled>
    </MenuBarProvider>
  )
}

const MainStyled = styled.div`
  display: flex;
  .content {
    width: 100%;
  }
`

export default Main
