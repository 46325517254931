import { Button, Icon } from '@keoworld/gbl-ui-kit'
import styled from 'styled-components'

const CommentsBar = ({ setOpenComments }) => {
  return (
    <LateralBarStyled>
      <Icon name='close' onClick={() => setOpenComments(false)} />
      <div className='content-lateral'>
        <h4>Comentarios</h4>
        <p>Doc: Constancia de situación fiscal (RFC)</p>
        <div className='input-content'>
          <textarea className='text-area' placeholder='Agrega un comentario' />
          <Button className='button'>Agregar</Button>
        </div>
        <div className='card'>
          <h4>
            <b>Nombre Apellido </b> &nbsp; 13/10/2022 - 13:04
          </h4>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, diam
            nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
            magna aliquam erat
          </p>
          <br />
        </div>
        <br />
      </div>
    </LateralBarStyled>
  )
}

const LateralBarStyled = styled.div`
  ${({ theme }) => theme.colors.grayShades[300]};
  width: 457px;
  border: 1px solid ${({ theme }) => theme.colors.grayShades[300]};
  border-radius: 24px 0px 0px;
  position: absolute;
  height: calc(100% - 70px);
  top: 70px;
  right: 0;

  .icon {
    margin: 1rem;
    cursor: pointer;
  }
  .content-lateral {
    margin: auto;
    width: 360px;
    h4 {
      font-size: 20px;
      font-weight: bold;
    }
    .input-content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      background-color: white;
      border-radius: 7px;
      width: 360px;
      min-height: 54px;
      .text-area {
        border-radius: 7px;
        min-height: 54px;
        border: none;
        font-family: 'Poppins';
        width: 200px;
        padding: 10px;
        resize: none;
      }
      .button {
        width: 82px;
        height: 30px;
      }
    }
    .card {
      background-color: white;
      border-radius: 7px;
      margin: 1rem auto 1rem auto;
      h4 {
        font-weight: lighter;
        font-size: 12px;
        padding: 1rem;
      }
      p {
        margin: auto 1rem;
      }
    }
  }
`

export default CommentsBar
