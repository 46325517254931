/**
 * Given an object, convert values that are number to string except the id
 * @param {object} object
 * @returns The converted object
 */
export const convertObjectValuesToString = async (object) => {
  return Object.entries(object).reduce((previousValue, [key, value]) => {
    if (typeof value === 'number' && key !== 'id') {
      return { ...previousValue, [key]: value.toString() }
    }
    return { ...previousValue, [key]: value }
  }, {})
}
