import { Icon, Input, Select } from '@keoworld/gbl-ui-kit'
import { useRef, useState } from 'react'
import styled from 'styled-components'
import { startCase } from 'utils/functions/text-convertor'
import { useOnClickOutside } from 'utils/hooks/click-outside'
import { CUSTOMER_STATES } from 'utils/schemas/customer'

const CustomerFilter = ({ setQuerySearch, setOffset, filters }) => {
  const [filter, setFilter] = useState('')
  const [filterName, setFilterName] = useState('')
  const filterRef = useRef(null)

  const onChangeFilter = (e) => {
    setFilter(e.target.value)
  }

  const clearFilter = () => {
    setFilter('')
    setFilterName('')
  }

  const search = (e) => {
    e.preventDefault()
    setQuerySearch({ [filterName]: filter })
    setOffset(0)
    clearFilter()
  }

  useOnClickOutside(filterRef, clearFilter)

  return (
    <CustomerFilterStyled>
      <Icon name='filter_alt' />
      {filters.map((name, index) => {
        const dropdownController = () => {
          setFilterName(name)
          setFilter('')
        }

        return (
          <button key={`filter-${index}`} onClick={dropdownController}>
            {name === 'dba' ? 'DBA' : startCase(name)}
            <Icon name='expand_more' />
          </button>
        )
      })}

      {filterName && (
        <form className='dropdown-filter' onSubmit={search} ref={filterRef}>
          {filterName === 'status' ? (
            <>
              <p className='label'>{filterName}</p>
              <div className='select-div'>
                <Select value={filter ?? ''} onChange={onChangeFilter}>
                  <option value='' disabled hidden>
                    Choose here
                  </option>
                  {Object.values(CUSTOMER_STATES).map((state, index) => {
                    const { stateLabel } = state
                    return (
                      <option
                        key={`option-${index}`}
                        value={Object.keys(CUSTOMER_STATES)[index]}
                      >
                        {stateLabel}
                      </option>
                    )
                  })}
                </Select>
                <Icon name='search' onClick={search} />
              </div>
            </>
          ) : (
            <Input
              className='filter'
              label={`${startCase(filterName)}:`}
              placeholder='ex: xxxx'
              icon={<Icon name='search' onClick={search} />}
              name={filterName}
              value={filter}
              onChange={onChangeFilter}
            />
          )}
        </form>
      )}
    </CustomerFilterStyled>
  )
}

const CustomerFilterStyled = styled.div`
  display: flex;
  justify-content: center;
  margin: 1.5rem 0;
  margin-right: 2rem;
  position: relative;

  .dropdown-filter {
    position: absolute;
    bottom: -60px;

    padding: 0 25px 10px;
    width: 400px;

    border-radius: 30px;

    ${({ theme }) => theme.colors.grayShades[500]}

    label {
      text-transform: uppercase;
    }
    .label {
      font-size: 10px;
      line-height: 0px;
    }
    input {
      ${({ theme }) => theme.colors.grayShades[500]}
    }

    select {
      ${({ theme }) => theme.colors.grayShades[500]}
    }
    .select-div {
      display: flex;
      .icon {
        padding-left: 10px;
      }
    }

    .icon {
      cursor: pointer;
    }
  }

  button {
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;

    text-transform: capitalize;
  }
`

export default CustomerFilter
