import { INPUT_TYPES } from 'containers/form'
import { FILE_STAGES } from 'utils/schemas/documents'
import { Validations } from 'utils/validators'

export const CUSTOMER_STAGES = {
  OnBoarding: 'OnBoarding',
  Operation: 'Operation',
  Underwriting: 'Underwriting',
  Legal: 'Legal',
  Rejected: 'Rejected',
}

export const CUSTOMER_STATES = {
  cust_prospectCreated: {
    stateLabel: 'Prospect Created',
    actionLabel: 'Generate Onboarding',
    stage: CUSTOMER_STAGES.OnBoarding,
    fileStage: FILE_STAGES.OnBoarding,
    roles: [
      'bpm.us-workeo.com-hd', //deprecated
      'bpm.us-workeo.ope-hd',
      'bpm.us-workeo.ope-an'
    ],
    transition: {
      SEND_LINK: {
        event: 'GENERATE_ONBOARDING_LINK',
        nextState: 'cust_externalOnBoarding'
      },
      SEND_BPM: {
        event: 'GENERATE_ONBOARDING_BPM',
        nextState: 'cust_onBoarding'
      },
      REJECT: {
        event: 'REJECT',
        nextState: 'cust_closedMissed'
      }
    }
  },
  cust_onBoarding: {
    stateLabel: 'Onboarding',
    actionLabel: 'Upload Onboarding Documents',
    stage: CUSTOMER_STAGES.OnBoarding,
    roles: [
      'bpm.us-workeo.com-hd', //deprecated
      'bpm.us-workeo.ope-hd',
      'bpm.us-workeo.ope-an'
    ],
    transition: {
      SEND: {
        event: 'SEND_ONBOARDING',
        nextState: 'cust_operationsAnalysis'
      },
      REJECT: {
        event: 'REJECT',
        nextState: 'cust_closedMissed'
      }
    }
  },
  cust_externalOnBoarding: {
    stateLabel: 'External Onboarding',
    stage: CUSTOMER_STAGES.OnBoarding,
    fileStage: FILE_STAGES.OnBoarding
  },
  cust_inOperationsReview: {
    stateLabel: 'Operations Review',
    actionLabel: 'Onboarding Documents Review',
    stage: CUSTOMER_STAGES.OnBoarding,
    fileStage: FILE_STAGES.OnBoarding,
    roles: ['bpm.us-workeo.ope-hd', 'bpm.us-workeo.ope-an'],
    transition: {
      SEND: {
        event: 'APPROVE_ONBOARDING',
        nextState: 'cust_operationsAnalysis'
      }
    }
  },
  cust_operationsAnalysis: {
    stateLabel: 'Operations Analysis',
    actionLabel: 'Upload Operational Documents',
    stage: CUSTOMER_STAGES.Operation,
    roles: ['bpm.us-workeo.ope-hd', 'bpm.us-workeo.ope-an'],
    transition: {
      SEND: {
        event: 'SEND_OPERATIONS',
        nextState: 'cust_rateProposal'
      },
      REJECT: {
        event: 'REJECT',
        nextState: 'cust_closedMissed'
      }
    }
  },
  cust_rateProposal: {
    stateLabel: 'Rate Proposal',
    actionLabel: 'Send Rate Proposal',
    stage: CUSTOMER_STAGES.Operation,
    roles: ['bpm.us-workeo.com-hd', 'bpm.us-workeo.com-ex'],
    transition: {
      SEND: {
        event: 'SEND_PROPOSAL',
        nextState: 'cust_inLegalReview'
      },
      REJECT: {
        event: 'REJECT',
        nextState: 'cust_closedMissed'
      }
    }
  },
  cust_inLegalReview: {
    stateLabel: 'Legal Review',
    actionLabel: 'Legal Review',
    stage: CUSTOMER_STAGES.Operation,
    isReviewDocuments: true,
    roles: ['bpm.us-workeo.lgl-hd', 'bpm.us-workeo.lgl-an'],
    transition: {
      SEND: {
        event: 'APPROVE_DOCS',
        nextState: 'cust_inUnderwriterReview'
      },
      REJECT: {
        event: 'REJECT',
        nextState: 'cust_closedMissed'
      }
    }
  },
  cust_inUnderwriterReview: {
    stateLabel: 'Underwriting Review',
    actionLabel: 'Underwriting Review',
    stage: CUSTOMER_STAGES.Operation,
    isReviewDocuments: true,
    roles: ['bpm.us-workeo.rsk-hd', 'bpm.us-workeo.rsk-an'],
    transition: {
      SEND: {
        event: 'APPROVE_DOCS',
        nextState: 'cust_underwritingAnalysis',
        nextStage: CUSTOMER_STAGES.Underwriting
      },
      REJECT: {
        event: 'REJECT',
        nextState: 'cust_closedMissed'
      }
    }
  },
  cust_underwritingAnalysis: {
    stateLabel: 'Underwriting Analysis',
    actionLabel: 'Upload Underwriting Documents',
    stage: CUSTOMER_STAGES.Underwriting,
    roles: ['bpm.us-workeo.rsk-hd', 'bpm.us-workeo.rsk-an'],
    transition: {
      SEND: {
        event: 'APPROVE_UW',
        nextState: 'cust_underwritingResult'
      },
      REJECT: {
        event: 'REJECT',
        nextState: 'cust_closedMissed'
      }
    }
  },
  cust_underwritingResult: {
    stateLabel: 'Underwriting Result',
    actionLabel: 'Check Underwriting Result',
    stage: CUSTOMER_STAGES.Underwriting,
    roles: ['bpm.us-workeo.com-hd', 'bpm.us-workeo.com-ex'],
    transition: {
      SEND: {
        event: 'APPROVE',
        nextState: 'cust_customerApproval'
      },
      REJECT: {
        event: 'REJECT',
        nextState: 'cust_closedMissed'
      }
    }
  },
  cust_customerApproval: {
    stateLabel: 'Customer Approval',
    actionLabel: 'Report Customer Response',
    stage: CUSTOMER_STAGES.Underwriting,
    roles: ['bpm.us-workeo.com-hd', 'bpm.us-workeo.com-ex'],
    transition: {
      SEND: {
        event: 'APPROVE_CUSTOMER',
        nextState: 'cust_uploadContract',
        nextStage: CUSTOMER_STAGES.Legal
      },
      REJECT: {
        event: 'REJECT_CUSTOMER',
        nextState: 'cust_underwritingAnalysis'
      }
    }
  },
  cust_uploadContract: {
    stateLabel: 'Upload Contract',
    actionLabel: 'Upload Loan Document',
    stage: CUSTOMER_STAGES.Legal,
    roles: ['bpm.us-workeo.lgl-hd', 'bpm.us-workeo.lgl-an'],
    transition: {
      SEND: {
        event: 'SEND_CONTRACT',
        nextState: 'cust_activate'
      },
      REJECT: {
        event: 'REJECT',
        nextState: 'cust_closedMissed'
      }
    }
  },
  cust_activate: {
    stateLabel: 'Active',
    stage: CUSTOMER_STAGES.Active
  },
  cust_closedMissed: {
    stateLabel: 'Prospect Rejected'
  }
}

export const CUSTOMER_FORM_SCHEMAS = {
  FORM_INFORMATION: [
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'name',
      label: 'Business Legal Name *',
      placeholder: 'Example: xxxxxx',
      validations: [Validations.isMandatory]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'dba',
      label: 'Business DBA Name *',
      placeholder: 'Example: xxxxxx',
      validations: [Validations.isMandatory]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'startedAt',
      label: 'Date Business Started',
      type: 'date',
      placeholder: 'Example: xx/xx/xx',
      validations: [Validations.any]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'email',
      label: 'Email *',
      placeholder: 'Example: xxxxxx',
      validations: [Validations.isMandatory, Validations.isEmail]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'phone',
      label: 'Business Phone *',
      placeholder: 'Example: xxxxxx',
      validations: [Validations.isMandatory, Validations.phone]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'address:location',
      label: 'Street / Number:',
      placeholder: 'Example: xxxxxx',
      validations: [Validations.alphaNumericSpace]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'fax',
      label: 'Business Fax',
      placeholder: 'Example: xxxxxx',
      validations: [Validations.alphaNumericSpace]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'detail:location',
      label: 'Suite/Floor',
      placeholder: 'Example: xxxxxx',
      validations: [Validations.alphaNumericSpace]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'businessType',
      label: 'Type of Business',
      placeholder: 'Example: xxxxxx',
      validations: [Validations.alphaNumericSpace]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'city:location',
      label: 'City',
      placeholder: 'Example: xxxxxx',
      validations: [Validations.alphaNumericSpace]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'state:location',
      label: 'State',
      placeholder: 'Example: xxxxxx',
      validations: [Validations.alphaNumericSpace]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'zip:location',
      label: 'Zip Code',
      placeholder: 'Example: xxxxxx',
      validations: [Validations.zipCode]
    },
    {
      inputType: INPUT_TYPES.SELECT,
      name: 'addressType:location',
      label: 'Is the address above the mailing or the office address?',
      options: [
        { label: 'Mailing', value: 'mailing' },
        { label: 'Address', value: 'address' }
      ],
      validations: [Validations.alphaNumericSpace]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'incorporationState',
      label: 'State of Incorporation',
      placeholder: 'Example: xxxxxx',
      validations: [Validations.alphaNumericSpace]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'employees',
      label: 'Number of Employees',
      placeholder: 'Example: 1',
      validations: [Validations.numbers]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'website',
      label: 'Website *',
      placeholder: 'Example: xxxxxx',
      validations: [Validations.isMandatory, Validations.any]
    }
  ],
  FORM_FINANCIAL_INFORMATION: [
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'clients',
      title: 'Write the clients separated by , (comma) and without space',
      label: 'Top Clients',
      placeholder: 'Example: xxx,xxx,xx',
      validations: [Validations.alphaNumWithComma]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'cashSalesPercentage:statement',
      label: '% of Cash Sales:',
      placeholder: 'Example: 100',
      validations: [Validations.percentage]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'mainSupplier:statement',
      label: 'Main Supplier',
      placeholder: 'Example: xxxxxxxx',
      validations: [Validations.alphaNumericSpace]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'internationalSalesPercentage:statement',
      label: '% of International Sales',
      placeholder: 'Example: 100',
      validations: [Validations.percentage]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'monthlySales:statement',
      label: 'Monthly Gross Sales',
      placeholder: 'Example: 1000',
      validations: [Validations.numbers]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'creditSalesPercentage:statement',
      label: '% of Credit Sales:',
      placeholder: 'Example: 100',
      validations: [Validations.percentage]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'yearlySales:statement',
      label: 'Yearly Gross Sales',
      placeholder: 'Example: 1000',
      validations: [Validations.numbers]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'nationalSalesPercentage:statement',
      label: '% of National Sales',
      placeholder: 'Example: 100',
      validations: [Validations.percentage]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'projectionNextYearlySales:statement',
      label: "Projection for next year's Gross Sales",
      placeholder: 'Example: 100',
      validations: [Validations.numbers]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'inventory:statement',
      label: 'How much $ in Inventory',
      placeholder: 'Example: 1000',
      validations: [Validations.numbers]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'averageTicketItem:statement',
      label: 'Average Ticket Item',
      placeholder: 'Example: 1000',
      validations: [Validations.numbers]
    },
    {
      inputType: INPUT_TYPES.SELECT,
      name: 'legalIssues:statement',
      label: 'Any Open Tax Liens, Bk, Judgments',
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
      ],
      validations: [Validations.alphaNumeric]
    },
    {
      inputType: INPUT_TYPES.SELECT,
      name: 'legalIssuesDischarged:statement',
      label:
        'IF YES, were the Tax Liens, Bankruptcy, and Judgments discharged? ',
      placeholder: 'Example: xxxxxx',
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
      ],
      validations: [Validations.alphaNumeric]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'legalIssuesDischargedAt:statement',
      label: 'IF YES, what is the date of the discharge?',
      placeholder: 'Example: xxxxxx',
      type: 'date',
      validations: [Validations.any]
    }
  ],
  FORM_LOAN_REQUEST: [
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'amount:loanRequest',
      label: 'Amount Requested',
      placeholder: 'Example: 1000',
      validations: [Validations.numbers]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'fundsUse:loanRequest',
      label: 'Use of funds',
      placeholder: 'Example: xxxxxxxx',
      validations: [Validations.alphaNumericSpace]
    }
  ],
  FORM_ADD_OWNER: [
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'name:owner',
      label: "Business Owner's Name",
      placeholder: 'Example: xxxxxx',
      validations: [Validations.isMandatory]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'email:owner',
      label: 'Email',
      placeholder: 'Example: xxxxxx',
      validations: [Validations.isMandatory, Validations.isEmail]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'address:owner.location',
      label: 'Street / Number',
      placeholder: 'Example: xxxxxx',
      validations: [Validations.isMandatory]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'mobile:owner',
      label: 'Mobile Phone',
      placeholder: 'Example: xxxxxx',
      validations: [Validations.isMandatory, Validations.phone]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'detail:owner.location',
      label: 'Suite/Floor',
      placeholder: 'Example: xxxxxx'
      // validations: [Validations.isMandatory]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'title:owner',
      label: 'Title',
      placeholder: 'Example: xxxxxx',
      validations: [Validations.isMandatory]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'city:owner.location',
      label: 'City',
      placeholder: 'Example: xxxxxx',
      validations: [Validations.isMandatory]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'state:owner.location',
      label: 'State',
      placeholder: 'Example: xxxxxx',
      validations: [Validations.isMandatory]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'zip:owner.location',
      label: 'Zip Code',
      placeholder: 'Example: xxxxxx',
      validations: [Validations.isMandatory, Validations.zipCode]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'ownershipPercentage:owner',
      label: '% of Ownership',
      placeholder: 'Example: 100',
      validations: [Validations.isMandatory, Validations.percentage]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'birthdate:owner',
      label: 'Date of birth',
      type: 'date',
      placeholder: 'Example: xx/xx/xx',
      validations: [Validations.isMandatory]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'ssn:owner',
      label: 'SSN',
      placeholder: 'Example: xxxxxx',
      validations: [Validations.isMandatory]
    }
  ],
  FORM_CONTACT: [
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'firstName:contact',
      label: 'FirstName *',
      placeholder: 'Example: xxxxxxxx',
      validations: [Validations.isMandatory, Validations.alphaNumericSpace]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'lastName:contact',
      label: 'LastName *',
      placeholder: 'Example: xxxxxxxx',
      validations: [Validations.isMandatory, Validations.alphaNumericSpace]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'email:contact',
      label: 'Email *',
      placeholder: 'Example: xxxxxxxx',
      validations: [Validations.isMandatory, Validations.isEmail]
    },
    {
      inputType: INPUT_TYPES.INPUT,
      name: 'mobilePhone:contact',
      label: 'Mobile Phone *',
      placeholder: 'Example: xxxxxxxx',
      validations: [Validations.isMandatory, Validations.phone]
    }
  ]
}

/* TODO : Update forms to customer form schemas */
export const customerFormTypes = {
  statement: 'statement',
  location: 'location',
  mortgage: 'mortgage',
  debt: 'debt',
  owner: 'owner',
  ownerLocation: 'ownerLocation'
}

export const CUSTOMER_INFORMATION = [
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'name',
    label: 'Business Legal Name *',
    placeholder: 'Example: xxxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'dba',
    label: 'Business DBA Name *',
    placeholder: 'Example: xxxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'email',
    label: 'Email *',
    placeholder: 'Example: xxxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'phone',
    label: 'Bus. Phone *',
    placeholder: 'Example: xxxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'fax',
    label: 'Bus. Fax',
    placeholder: 'Example: xxxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'employees',
    label: 'Number of Employees',
    type: 'number',
    placeholder: 'Example: 1'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'website',
    label: 'Website',
    placeholder: 'Example: xxxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'legalEntity',
    label: 'Legal Entity',
    placeholder: 'Example: xxxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'businessType',
    label: 'Type of Business',
    placeholder: 'Example: xxxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'taxId',
    label: 'Federal Tax ID# (EIN)',
    placeholder: 'Example: xxxxxxxx'
  },
  {
    inputType: INPUT_TYPES.SELECT,
    name: 'seasonal',
    label: 'Is Business Seasonal?',
    options: [
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ]
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'startedAt',
    label: 'Date Business Started',
    type: 'date',
    placeholder: 'Example: xx/xx/xx'
  }
]

export const CUSTOMER_STATEMENT = [
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'monthlySales',
    label: 'Monthly Gross Sales',
    type: 'number',
    placeholder: 'Example: 1000'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'yearlySales',
    label: 'Yearly Gross Sales',
    type: 'number',
    placeholder: 'Example: 1000'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'supplier',
    label: 'Supplier',
    placeholder: 'Example: xxxxxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'inventory',
    label: 'Total Amount in Inventory',
    type: 'number',
    placeholder: 'Example: 1000'
  },
  {
    inputType: INPUT_TYPES.SELECT,
    name: 'legalIssues',
    label: 'Any Open Tax Liens, Bk, Judgments',
    options: [
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ]
  }
]

export const CUSTOMER_MORTGAGE = [
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'amount',
    label: 'Rent/Mortgage amount',
    type: 'number',
    placeholder: 'Example: xxxxxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'landlord',
    label: 'Landlord/Mortgage Company',
    placeholder: 'Example: xxxxxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'contactName',
    label: 'Landlord Contact Name',
    placeholder: 'Example: xxxxxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'contactPhone',
    label: 'Landlord Contact Phone',
    placeholder: 'Example: xxxxxxxx'
  }
]

export const FINANCIAL_INFORMATION = [
  {
    inputType: INPUT_TYPES.SELECT,
    name: 'existingLoan',
    label: 'Existing Loan or Financing',
    options: [
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ]
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'lender',
    label: 'Name of Lender',
    placeholder: 'Example: xxxxxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'originalAmount',
    label: 'Original Amount',
    type: 'number',
    placeholder: 'Example: 1000'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'balance',
    label: 'Current balance',
    type: 'number',
    placeholder: 'Example: 1000'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'formClients',
    label: 'Top 3 Clients',
    placeholder: 'Example: xxxxxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'paymentAmount',
    label: 'Payment Amount',
    type: 'number',
    placeholder: 'Example: 1000'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'paymentFrequency',
    label: 'Frequency',
    placeholder: 'Example: xxxxxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'fundsUse',
    label: 'Use of funds',
    placeholder: 'Example: xxxxxxxx'
  }
]

export const OWNER_INFORMATION = [
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'name',
    label: 'Name',
    placeholder: 'Example: xxxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'birthdate',
    label: 'Date of birth',
    type: 'date',
    placeholder: 'Example: xx/xx/xx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'mobile',
    label: 'Mobile Phone',
    placeholder: 'Example: xxxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'driverLicense',
    label: 'Driver`s license #',
    placeholder: 'Example: xxxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'email',
    label: 'Email',
    placeholder: 'Example: xxxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'title',
    label: 'Title',
    placeholder: 'Example: xxxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'ssn',
    label: 'SSN',
    placeholder: 'Example: xxxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'ownershipPercentage',
    label: '% of Ownership',
    type: 'number',
    placeholder: 'Example: 100'
  }
]

export const LOCATION = [
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'address',
    label: 'Address',
    placeholder: 'Example: xxxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'detail',
    label: 'Suite/Floor',
    placeholder: 'Example: xxxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'zip',
    label: 'Zip',
    placeholder: 'Example: xxxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'state',
    label: 'State',
    placeholder: 'Example: xxxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'city',
    label: 'City',
    placeholder: 'Example: xxxxxx'
  }
]

export const LOCATION_TWO = [
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'address',
    label: 'Address',
    placeholder: 'Example: xxxxxx'
  },

  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'zip',
    label: 'Zip',
    placeholder: 'Example: xxxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'state',
    label: 'State',
    placeholder: 'Example: xxxxxx'
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    name: 'city',
    label: 'City',
    placeholder: 'Example: xxxxxx'
  }
]
