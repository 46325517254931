import { createContext, useEffect, useState } from 'react'
import { getCustomerInstance } from 'services/workflow'

export const CUSTOMER_DETAIL_SECTIONS = {
  INFORMATION: 'information',
  DOCUMENTS: 'documents',
  COMMENTS: 'comments',
  LINE_CONDITIONS: 'lineConditions',
  PREAPPROVE: 'preapprove',
  HISTORY: 'history',
  COMERCIAL_ACTIVITY: 'comercialActivity',
  LEGAL_RISK: 'legalRisk'
}

const context = {
  customer: { id: '', name: '', email: '', state: '' },
  currentSection: CUSTOMER_DETAIL_SECTIONS.INFORMATION,
  setCustomer: undefined,
  setCurrentSection: undefined
}

export const CustomerDetailContext = createContext(context)

export const CustomerDetailProvider = ({ children }) => {
  const [customer, setCustomer] = useState(context.customer)
  const [currentSection, setCurrentSection] = useState(context.currentSection)
  //
  const [workflowStatus, setWorkflowStatus] = useState();

  useEffect(() => {
    const fetchInstance = async () => {
      try {
        const { data: { body } } = await getCustomerInstance(customer?.id)
        if (body?.instance)
          setWorkflowStatus(body.instance)
      } catch (err) {
        console.error("error", err)
      }
    }

    if (customer?.id && customer?.id !== "" )
      fetchInstance()
  }, [customer?.id])

  return (
    <CustomerDetailContext.Provider
      value={{ customer, setCustomer, currentSection, setCurrentSection, workflowStatus }}
    >
      {children}
    </CustomerDetailContext.Provider>
  )
}
