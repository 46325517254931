import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import Chip from 'components/chip'
import PaginatedItems from 'components/pagination'
import CustomerFilter from 'containers/dashboard/customer-filter'
import { CustomerDetailContext } from 'providers/customer-detail'
import { LATERAL_MENU_OPTIONS, MenuBarContext } from 'providers/menu-bar'
import { useContext, useEffect, useState } from 'react'
import { getCustomers } from 'services/customer'
import styled from 'styled-components'
import { dateFormatFromIso } from 'utils/functions/date'
import { CUSTOMER_STATES } from 'utils/schemas/customer'

const MAX_ITEMS_PER_PAGE = 20
const CUSTOMERS_FILTERS = ['businessName', 'dba', 'businessType']
const DASHBOARD_COLUMNS = [
  'Business Name',
  'DBA',
  'Business Type',
  'Updated At'
]
const initialFilter = {
  stage: 'Active'
}

const CustomerDashboard = () => {
  const [loading, setLoading] = useState(true)
  const [filter, setFilter] = useState(initialFilter)
  const [offset, setOffset] = useState(0)
  const [totalItems, setTotalItems] = useState(10)
  const [customers, setCustomers] = useState([])
  const { setSelectedOption } = useContext(MenuBarContext)
  const { setCustomer } = useContext(CustomerDetailContext)

  useEffect(() => {
    const obtainCustomerList = async () => {
      setLoading(true)
      const query = {
        page: offset,
        size: MAX_ITEMS_PER_PAGE
      }
      const { data } = await getCustomers(filter, query)
      setCustomers(data.data)
      setTotalItems(data.count)
      setLoading(false)
    }
    obtainCustomerList()
  }, [filter, offset])

  const chips = Object.entries(filter)
    .map(([key, value]) => {
      if (key === 'states' && value) {
        value = CUSTOMER_STATES[value ?? 'cust_prospectCreated']?.stateLabel
        return { key, value }
      } else if (key !== 'stage' && value) {
        return { key, value }
      }
      return undefined
    })
    .filter((obj) => Boolean(obj))

  return (
    <CustomerListStyled>
      {loading && (
        <div className="center-loading">
          <LoadingAnimation />
        </div>
      )}
      {!loading && (
        <>
          <CustomerFilter
            setQuerySearch={(query) => setFilter({ ...filter, ...query })}
            setOffset={setOffset}
            filters={CUSTOMERS_FILTERS}
          />
          {Boolean(chips.length) && (
            <div className="chips">
              {chips.map(({ key, value }, index) => {
                const label = `${key.replace(/([a-z])([A-Z])/g, '$1 $2')}: ${
                  CUSTOMER_STATES[value]?.stateLabel ?? value
                }`
                const onClose = () => setFilter({ ...filter, [key]: '' })
                return (
                  <Chip key={`chip-${index}`} label={label} onClose={onClose} />
                )
              })}
            </div>
          )}
          <table>
            <thead>
              <tr>
                <th />
                {DASHBOARD_COLUMNS.map((column, index) => (
                  <th key={`column-${index}`}>{column}</th>
                ))}
                <th />
              </tr>
            </thead>
            <tbody>
              {customers.map((customer, index) => {
                const onClickAction = () => {
                  setCustomer(customer)
                  setSelectedOption(LATERAL_MENU_OPTIONS.CUSTOMER_DETAIL)
                }

                return (
                  <tr key={`customer-${index}`}>
                    <td>
                      <div className="profile-picture">
                        <span>{customer.name.slice(0, 2).toUpperCase()}</span>
                      </div>
                    </td>
                    <td>{customer.name}</td>
                    <td>{customer.dba}</td>
                    <td>{customer.businessType}</td>
                    <td>
                      {customer.updatedAt
                        ? dateFormatFromIso(customer.updatedAt)
                        : '-'}
                    </td>
                    <td>
                      <Button className="action" onClick={onClickAction}>
                        See More
                      </Button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </>
      )}
      {!loading && totalItems > MAX_ITEMS_PER_PAGE && (
        <div className="pagination">
          <PaginatedItems
            currentPage={offset}
            handlePageClick={(event) => setOffset(event.selected)}
            pageCount={Math.ceil(totalItems / MAX_ITEMS_PER_PAGE)}
          />
        </div>
      )}
    </CustomerListStyled>
  )
}

const CustomerListStyled = styled.div`
  .center-loading {
    height: 90vh;
    display: flex;
    align-items: center;
    div {
      width: 150px;
    }
  }

  table {
    width: 90%;
    margin: 20px auto;
    text-align: center;

    tbody > tr {
      height: 70px;
      position: relative;

      td {
        .profile-picture {
          width: 50px;
          height: 50px;

          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          border-radius: 50%;
          border: 2px solid
            ${({ theme }) => theme.colors.grayShades[700].backgroundColor};
          ${({ theme }) => theme.colors.grayShades[300]};
        }

        button.action {
          width: 120px;
          height: 30px;
        }
      }

      &::after {
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        bottom: 0;
        left: 0;
        background-color: ${({ theme }) =>
          theme.colors.grayShades[400].backgroundColor};
      }
    }
  }

  .chips {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 1.5rem;
    margin-right: 1.5rem;
    justify-content: center;
    padding: 0 15px;
  }

  .pagination {
    margin: 1rem auto;
    ul {
      margin: auto;
    }
  }
`

export default CustomerDashboard
