import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import CustomerForm from 'containers/on-boarding/customer-form'
import { AlertContext } from 'providers/alert'
import { CustomerDetailContext } from 'providers/customer-detail'
import { LATERAL_MENU_OPTIONS, MenuBarContext } from 'providers/menu-bar'
import { useContext, useEffect, useState } from 'react'
import { getCustomerDetail, updateCustomer } from 'services/customer'
import styled from 'styled-components'
import {
  formatCreateOwner,
  formatInitialForm,
  updateFormClients
} from 'utils/functions/format-customer-form'
import { CUSTOMER_FORM_SCHEMAS } from 'utils/schemas/customer'
import { validateForm, validateFormInputNotEmpty } from 'utils/validators'

const CustomerInformation = () => {
  const [formValuesInitial, setFormValuesInitial] = useState({})
  const [arrayClients, setArrayClients] = useState()
  const [formValues, setFormValues] = useState({})
  const [formErrors, setFormErrors] = useState({})
  const { selectedOption, setSelectedOption } = useContext(MenuBarContext)
  const { customer } = useContext(CustomerDetailContext)
  const { setAlert } = useContext(AlertContext)
  const [isValidating, setIsValidating] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isLoadingButton, setIsLoadingButton] = useState(false)

  const formatOwners = async () => {
    const array = []
    formValues.owners?.forEach(async (owner) => {
      const newOwner = await formatCreateOwner(owner)
      if (owner.id) {
        newOwner.id = owner.id
        newOwner.location.id = owner.locationId
      }
      array.push(newOwner)
    })
    return array
  }

  const onHandleSubmit = async () => {
    setIsValidating(true)
    setIsLoadingButton(true)
    try {
      Object.entries(formValues).forEach(([key, value]) => {
        if (typeof value === 'string') formValues[key] = value.trim()
        if (value === '') formValues[key] = null
      })
      formValues.owners?.forEach((owner)=>{
        Object.entries(owner).forEach(([key, value]) => {
          if (typeof value === 'string') owner[key] = value.trim()
        })
      })
      let formToUpdate = {
        id: formValues.customerId,
        location: { id: formValues.locationId },
        statement: { id: formValues.statementId },
        loanRequest: { id: formValues.loanRequestId },
        contact: { id: formValues.contactId }
      }

      Object.keys(formValues).forEach((field) => {
        if (formValues[field] !== formValuesInitial[field]) {
          let keyAndValue = field.split(':')
          if (keyAndValue.length > 1) {
            let prev = formToUpdate[keyAndValue[1]]
            formToUpdate[keyAndValue[1]] = {
              ...prev,
              [keyAndValue[0]]: formValues[field]
            }
          } else {
            formToUpdate[field] = formValues[field]
          }
        }
      })
      formToUpdate.owners = await formatOwners()
      formToUpdate.clients = await updateFormClients(
        formValues.clients,
        arrayClients
      )

      /* Identify form errors by fields validating */
      const ownerErrors =
        formValues?.owners?.map((itm) =>
          validateForm(CUSTOMER_FORM_SCHEMAS.FORM_ADD_OWNER, itm)
        ) ?? []
      /* Check if exist form errors */

      const formValid =
        isEmpty(formErrors) && !ownerErrors.find((itm) => !isEmpty(itm))

      if (formToUpdate.startedAt === '') delete formToUpdate.startedAt

      if (formValid) {
        await updateCustomer(formToUpdate)
        redirectView()
      } else {
        throw new Error('Form Not Valid')
      }
    } catch (err) {
      console.error(err)
      const message = err?.message
        ? `with the following message: ${err?.message}`
        : ''
      setAlert({
        title: 'Error',
        label: `An error ocurred ${message}. Please check that you have filled out the form correctly.`,
        type: 'error'
      })
    } finally {
      setIsLoadingButton(false)
    }
  }

  useEffect(() => {
    const fetchCustomerDetail = async () => {
      setLoading(true)
      const { data } = await getCustomerDetail(customer?.id)
      const { form } = formatInitialForm(data)
      setArrayClients(data.clients)
      setFormValuesInitial(form)
      setFormValues(form)
    }
    fetchCustomerDetail()
  }, [customer?.id])

  useEffect(() => {
    if (formValues.customerId) {
      setLoading(false)
    }
  }, [formValues.customerId])

  useEffect(() => {
    const schemas = [
      ...CUSTOMER_FORM_SCHEMAS.FORM_INFORMATION,
      ...CUSTOMER_FORM_SCHEMAS.FORM_FINANCIAL_INFORMATION,
      ...CUSTOMER_FORM_SCHEMAS.FORM_LOAN_REQUEST,
      ...CUSTOMER_FORM_SCHEMAS.FORM_CONTACT
    ]
    /* Identify form errors by fields validating */
    const formErrors = validateFormInputNotEmpty(schemas, formValues)
    setFormErrors(formErrors)
  }, [formValues])

  const redirectView = () => {
    const redirectTo =
      selectedOption === LATERAL_MENU_OPTIONS.ONBOARDING_DETAIL
        ? LATERAL_MENU_OPTIONS.DASHBOARD
        : LATERAL_MENU_OPTIONS.DASHBOARD_CUSTOMER
    setSelectedOption(redirectTo)
  }

  return (
    <CustomerInformationStyled>
      {loading && (
        <div className="center-loading">
          <LoadingAnimation />
        </div>
      )}
      {!loading && (
        <div>
          <CustomerForm
            formValues={formValues}
            setFormValues={setFormValues}
            formErrors={formErrors}
            isValidating={isValidating}
            info
          />
          <div className="action-group">
            <Button buttonType="grayButton" onClick={() => redirectView()}>
              Cancel
            </Button>
            <Button onClick={onHandleSubmit} disabled={isLoadingButton}>
              {isLoadingButton ? (
                <LoadingAnimation className="loading" />
              ) : (
                'Update'
              )}
            </Button>
          </div>
        </div>
      )}
    </CustomerInformationStyled>
  )
}

/**
 *
 * @param {object} obj
 * @returns
 */
const isEmpty = (obj) => Object.keys(obj).length === 0

const CustomerInformationStyled = styled.section`
  padding: 2rem;
  .action-group {
    margin: 2rem 42px 0;

    button + button {
      margin-left: 28px;
    }
  }

  .loading span {
    background-color: white;
  }

  .error-message {
    color: red;
    font-weight: bold;
  }
`

export default CustomerInformation
