/**
 *
 * @param {object} obj
 * @returns {FormData}
 */
export const objectToFormData = (obj) => {
  const formData = new FormData()
  Object.entries(obj).forEach(([key, value]) => {
    formData.append(key, value)
  })
  return formData
}

export const CurrencyFormatUSD = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

export const capitalize = (word) => {
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
}