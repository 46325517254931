import Form from 'containers/form'
import { CUSTOMER_FORM_SCHEMAS } from 'utils/schemas/customer'
import IconButton from 'components/icon-button'
import { Icon } from '@keoworld/gbl-ui-kit'
import styled from 'styled-components'
import { useEffect, useState } from 'react'

const OwnerForm = ({ formValues, setFormValues, isValidating }) => {
  const [formErrors, setFormErrors] = useState([])

  useEffect(() => {
    const arrayErrors = (formValues?.owners ?? []).map((_, idx) => {
      return formErrors[idx] ? { ...formErrors[idx] } : {}
    })
    setFormErrors(arrayErrors)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.owners])

  const onDelete = (index) => {
    const newOwners = formValues.owners.filter((_, i) => i !== index)
    setFormValues((prev) => ({
      ...prev,
      owners: newOwners
    }))
  }

  const setError = (data, index) => {
    setFormErrors([...formErrors, (formErrors[index] = data)])
  }

  const setOwner = (data, index) => {
    const newOwners = [...formValues.owners]
    newOwners[index] = data
    setFormValues({
      ...formValues,
      owners: newOwners
    })
  }

  const addNewOwner = () => {
    setFormValues((prev) => {
      const currentOwners = prev?.owners ?? []
      return {
        ...prev,
        owners: [...currentOwners, {}]
      }
    })
  }

  return (
    <StyledOwnerForm className='collapsable'>
      {(formValues?.owners ?? []).map((owner, index) => (
        <div className='owner' key={`owner-${index}`}>
          <div className='button-group'>
            <div />
            <IconButton
              className='delete'
              onClick={() => onDelete(index)}
              size='action'
              buttonType='outline'
            >
              <Icon name='delete_forever' />
            </IconButton>
          </div>
          <div className='section-content'>
            <Form
              schema={CUSTOMER_FORM_SCHEMAS.FORM_ADD_OWNER}
              formValues={owner}
              setFormValues={(data) => setOwner(data, index)}
              formErrors={formErrors[index]}
              setFormErrors={(data) => setError(data, index)}
              isValidating={isValidating}
            />
          </div>
        </div>
      ))}
      <div className='button-group'>
        <IconButton onClick={() => addNewOwner()}>
          <Icon name='add_circle' />
          Add Owner
        </IconButton>
        <div />
      </div>
    </StyledOwnerForm>
  )
}

const StyledOwnerForm = styled.div`
  .button-group  {
    display: flex;
    justify-content: space-between;
    margin: 1em 0 0.1em 0;
  }
`

export default OwnerForm
