import { jwtToJson } from 'utils/functions/json-translator'
import { CUSTOMER_STATES } from 'utils/schemas/customer'

/**
 * function to obtain the roles from oauth token
 * @returns {Array} - Array of roles
 */
export const getUserRoles = () => {
  const oauthIdToken = window.localStorage.getItem('oauthIdToken')
  if (!oauthIdToken) return []
  const token = jwtToJson(oauthIdToken)
  return token.payload.roles ?? []
}

/**
 * function to check if the user's role has permission to access a specific state
 * @param {string} currentState - Current state
 * @returns {boolean}
 */
export const validateStateRole = (currentState) => {
  if (!currentState) return false
  const [role] = getUserRoles()
  const stateRoles = CUSTOMER_STATES[currentState]?.roles
  if (!role) return false
  if (!stateRoles) return false
  return stateRoles.includes(role)
}
