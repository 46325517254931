import { Button, Icon, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import Chip from 'components/chip'
import PaginatedItems from 'components/pagination'
import CustomerFilter from 'containers/dashboard/customer-filter'
import CustomerList from 'containers/dashboard/customer-list'
import StageFilter from 'containers/dashboard/stage-filter'
import { CustomerDetailContext } from 'providers/customer-detail'
import { LATERAL_MENU_OPTIONS, MenuBarContext } from 'providers/menu-bar'
import { useContext, useEffect, useRef, useState } from 'react'
import { getCustomers } from 'services/customer'
import styled from 'styled-components'
import { getUserRoles } from 'utils/functions/role-manager'
import { CUSTOMER_STATES } from 'utils/schemas/customer'

const CUSTOMERS_FILTERS = ['businessName', 'dba', 'businessType', 'status']
const FILTER_STAGE = 'OnBoarding,Operation,Underwriting,Legal'
const MAX_ITEMS_PER_PAGE = 20
const initialFilter = {
  businessName: '',
  businessType: '',
  dba: '',
  legalEntity: '',
  stage: FILTER_STAGE,
  states: '',
  status: ''
}

const OnboardingDashboard = () => {
  const [customers, setCustomers] = useState([])
  const [filter, setFilter] = useState(initialFilter)
  const [beforeFilter, setBeforeFilter] = useState(initialFilter)
  const [filterStage, setFilterStage] = useState('')
  const [loading, setLoading] = useState(true)
  const { setSelectedOption } = useContext(MenuBarContext)
  const { setCustomer } = useContext(CustomerDetailContext)
  const [offset, setOffset] = useState(0)
  const [totalItems, setTotalItems] = useState(10)
  const toResetErrorRef = useRef(null)
  const [role] = getUserRoles()

  const ALLOWED_ROLES = [
    'bpm.us-workeo.com-hd',
    'bpm.us-workeo.com-ex',
    'bpm.us-workeo.ope-hd',
    'bpm.us-workeo.ope-an'
  ]

  const chips = Object.entries(filter)
    .map(([key, value]) => {
      if (key === 'states' && value) {
        value = CUSTOMER_STATES[value ?? 'cust_prospectCreated']?.stateLabel
        return { key, value }
      } else if (key !== 'stage' && value) {
        return { key, value }
      }
      return undefined
    })
    .filter((obj) => Boolean(obj))

  useEffect(() => {
    const obtainCustomerList = async () => {
      setLoading(true)
      const query = {
        page: offset,
        size: MAX_ITEMS_PER_PAGE
      }
      if (JSON.stringify(filter) !== JSON.stringify(beforeFilter)) {
        query.page = 0
        setOffset(0)
      }
      const { data } = await getCustomers(filter, query)
      setCustomers(data.data)
      setTotalItems(data.count)
      setLoading(false)
    }
    obtainCustomerList()
  }, [beforeFilter, filter, offset])

  useEffect(() => {
    if (toResetErrorRef) {
      toResetErrorRef.current.scrollIntoView()
    }
  }, [])

  useEffect(() => {
    setCustomer({})
  }, [setCustomer])

  const changeBeforeFilter = async () => {
    setBeforeFilter(filter)
  }

  const changeStateFilter = (stage) => {
    const stageSearch = stage === '' ? FILTER_STAGE : stage
    setFilterStage(stage)
    changeBeforeFilter()
    setFilter({ ...filter, stage: stageSearch })
  }

  return (
    <CustomerListStyled>
      <div ref={toResetErrorRef}>
        <StageFilter
          stage={filterStage}
          setStage={(stage) => {
            changeStateFilter(stage)
          }}
        />
        <CustomerFilter
          setQuerySearch={(query) => setFilter({ ...filter, ...query })}
          setOffset={setOffset}
          filters={CUSTOMERS_FILTERS}
        />
        {Boolean(chips.length) && (
          <div className="chips">
            {chips.map(({ key, value }, index) => {
              const label = `${key.replace(/([a-z])([A-Z])/g, '$1 $2')}: ${
                CUSTOMER_STATES[value]?.stateLabel ?? value
              }`
              const onClose = () => setFilter({ ...filter, [key]: '' })
              return (
                <Chip key={`chip-${index}`} label={label} onClose={onClose} />
              )
            })}
          </div>
        )}
        {loading && (
          <div className="center-loading">
            <LoadingAnimation />
          </div>
        )}
        {!loading && (
          <>
            <CustomerList customers={customers} />
            {totalItems > MAX_ITEMS_PER_PAGE && (
              <div className="pagination">
                <PaginatedItems
                  currentPage={offset}
                  handlePageClick={(event) => {
                    setBeforeFilter(filter)
                    setOffset(event.selected)
                  }}
                  pageCount={Math.ceil(totalItems / MAX_ITEMS_PER_PAGE)}
                />
              </div>
            )}
          </>
        )}
        {ALLOWED_ROLES.includes(role) && (
          <Button
            className="onboarding"
            size="action"
            id="Prospect Creation"
            onClick={() =>
              setSelectedOption(LATERAL_MENU_OPTIONS.ONBOARDING_CREATE)
            }
          >
            <Icon name="add" />
          </Button>
        )}
      </div>
    </CustomerListStyled>
  )
}

const CustomerListStyled = styled.div`
  .center-loading {
    height: 90vh;
    display: flex;
    align-items: center;
    div {
      width: 150px;
    }
  }

  .onboarding {
    position: absolute;
    right: 1rem;
    top: 5rem;
  }

  .chips {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 1.5rem;
    margin-right: 1.5rem;
    padding: 0 15px;
    justify-content: center;
  }
  .pagination {
    margin: 1rem auto;
    ul {
      margin: auto;
    }
  }
`

export default OnboardingDashboard
