import {
  MAXIMUM_AMOUNT_PER_LINE_I2C,
  MINIMUM_AMOUNT_PER_LINE_I2C
} from './constants'

export const Validations = {
  isMandatory: 'mandatory',
  isEmail: 'email',
  alphaNumeric: 'alphaNum',
  alphaNumericSpace: 'alphaNumSpace',
  creditLineRequest: 'creditLineRequest',
  numbers: 'numbers',
  string: 'string',
  phone: 'phone',
  percentage: 'percentage',
  decimal: 'decimal',
  any: 'any',
  alphaNumWithComma: 'alphaNumWithComma',
  zipCode: 'zipCode'
}

const validators = {
  mandatory: (value) => Boolean(value),
  email: (value) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value),
  alphaNum: (value) =>
    /^[a-zA-ZÀ-ÿ0-9\u00f1\u00d1]*[a-zA-ZÀ-ÿ0-9\u00f1\u00d1]+$/.test(value),
  alphaNumWithComma: (value) =>
    /^([ a-zA-ZÀ-ÿ0-9\u00f1\u00d1],?)*([a-zA-ZÀ-ÿ0-9\u00f1\u00d1],?)+$/.test(
      value
    ),
  numbers: (value) =>
    value !== '' && !isNaN(value) && /^(0|[1-9]\d*)(\.\d+)?$/.test(value),
  creditLineRequest: (value) => {
    value = parseInt(value)
    return (
      value !== '' &&
      !isNaN(value) &&
      value >= MINIMUM_AMOUNT_PER_LINE_I2C &&
      value <= MAXIMUM_AMOUNT_PER_LINE_I2C &&
      /^(0|[1-9]\d*)(\.\d+)?$/.test(value)
    )
  },
  zipCode: (value) => value !== '' && /^[0-9]{5}$/.test(value),
  string: (value) =>
    /^[a-zA-ZÀ-ÿ\u00f1\u00d1]*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/.test(value),
  phone: (value) => /^[0-9]{10}$/.test(value),
  percentage: (value) =>
    value !== '' && value > 0 && /\b(0*(?:[0-9][0-9]?|100))\b/.test(value),
  alphaNumSpace: (value) =>
    /^[-_ a-zA-ZÀ-ÿ0-9\u00f1\u00d1]*[a-zA-ZÀ-ÿ0-9\u00f1\u00d1]+$/.test(value),
  decimal: (value) =>
    value !== '' && /^(0|[1-9]\d*)?(\.\d+)?(?<=\d)$/.test(value),
  any: (value) => Boolean(value)
}

/**
 * @param {string} value
 * @param {string} type
 * @returns
 */
export const validateValue = (value, type = 'any') => {
  if (typeof value === 'string') {
    value = value.trim()
    return validators[type](value)
  } else {
    return validators[type](value)
  }
}

/**
 *
 * @param {object} fullSchema
 * @param {object} formValues
 * @param {function} formValues
 */
export const validateFormInputNotEmpty = (
  fullSchema,
  formValues,
  isValidating = () => true
) =>
  fullSchema.reduce((acc, { name, validations = [] }) => {
    const invalidCriteria = validations.filter((itm) => {
      if (itm === 'mandatory') {
        return !validateValue(formValues[name], itm) && isValidating(name)
      } else {
        if (
          formValues[name] !== undefined &&
          formValues[name] !== '' &&
          formValues[name] !== null
        )
          return !validateValue(formValues[name], itm) && isValidating(name)
        else return false
      }
    })
    const hasInvalidCriteria = invalidCriteria.length > 0
    if (hasInvalidCriteria) {
      return { ...acc, [name]: invalidCriteria }
    }
    return acc
  }, {})

export const validateForm = (
  fullSchema,
  formValues,
  isValidating = () => true
) =>
  fullSchema.reduce((acc, { name, validations = [] }) => {
    const invalidCriteria = validations.filter(
      (itm) => !validateValue(formValues[name], itm) && isValidating(name)
    )
    const hasInvalidCriteria = invalidCriteria.length > 0
    if (hasInvalidCriteria) {
      return { ...acc, [name]: invalidCriteria }
    }
    return acc
  }, {})
