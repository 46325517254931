import { Icon } from '@keoworld/gbl-ui-kit'
import { useRef } from 'react'
import styled from 'styled-components'

/**
 * 
 * @param {boolean} value 
 * @param {function} setValue 
 * @returns 
 */
const CheckRadioButton = ({ value = '', setValue }) => {
	const checkRefs = {
		acceptRef: useRef(),
		rejectRef: useRef()
	}	

	const handleChange = (e) =>
		setValue(e.target?.value)
	
	const handleClick = (type) =>
		checkRefs[type].current.click() 

	return <StyledCheck onChange={handleChange}>
		<StyledCheckBox className='check-box' type='accept' state={value} onClick={()=>handleClick('acceptRef')}>
			<Icon type="outlined" name="check_circle"/>
			<input ref={checkRefs.acceptRef} type="radio" id="accept" name="check-field" value="accept" hidden/>
		</StyledCheckBox>
		<StyledCheckBox className='check-box' type='reject' state={value} onClick={()=>handleClick('rejectRef')}>
			<Icon type="outlined" name="cancel"/>
			<input ref={checkRefs.rejectRef} type="radio" id="reject" name="check-field" value="reject" hidden/>
		</StyledCheckBox>
	</StyledCheck>
}

const StyledCheck = styled.div`
	display: flex;
	margin: 0 1em;

	.check-box{
		cursor: pointer;
		justify-content: center;
    border: 2px solid;
    height: 3.5em;
    width: 3.5em;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin: 0.2em;
	}

	span: {
		font-size: 2.5em;
	}
`

const colors = {
	accept: {
		color: '#2BB161',
		backgroundActive: '#89edb1',
		backgroundHover: '#58d389'
	},
	reject: {
		color: '#FF0045',
		backgroundActive: '#ff96b2',
		backgroundHover: '#ff497a'
	}
}

const StyledCheckBox = styled.div`
	color: ${({type})=> colors[type].color ?? 'grey' };
	&:hover {
		background-color: ${({type})=> colors[type].backgroundHover ?? 'grey' };	
	}

	background-color: ${({state, type})=> state === type ? colors[type].backgroundActive : 'none' };
`

export default CheckRadioButton