import Form, { INPUT_TYPES } from 'containers/form'
import styled from 'styled-components'

const COMMENT = [
  {
    inputType: INPUT_TYPES.TEXT_AREA,
    name: "comment",
    placeholder: "Comments",
    validations: []
  }
]

const Comment = ({ formValues, setFormValues, formErrors, setFormErrors, isValidating }) => {

  return (
    <StyledComment>
      <Form
        schema={COMMENT}
        formValues={formValues}
        setFormValues={setFormValues}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        isValidating={isValidating}
      />
    </StyledComment>
  )
}

const StyledComment = styled.div`

`


export default Comment