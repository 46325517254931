import { THEME } from '@keoworld/gbl-ui-kit'
import '@keoworld/gbl-ui-kit/styles/index.css'
import App from 'App'
import { AlertProvider } from 'providers/alert'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { SessionProvider } from './providers/session-manager'
import reportWebVitals from './reportWebVitals'
import { setUpAxios } from 'services/config'

setUpAxios()

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <React.StrictMode>
      <SessionProvider>
        <ThemeProvider theme={THEME}>
          <AlertProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </AlertProvider>
        </ThemeProvider>
      </SessionProvider>
    </React.StrictMode>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
