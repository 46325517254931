import styled from 'styled-components'

const Header = ({ customer, currentTab, setCurrentTab, headerOptions }) => {
  const customerName = customer?.name ?? ''
  const customerId = customer?.id ?? ''

  return (
    <HeaderStyled>
      <div className='information'>
        <div className='path'>
          Home {'>'} Customer Detail {'>'} {customerName}
        </div>
        <div className='customer'>
          <h3>{customerName}</h3>
          <span>ID: {customerId}</span>
        </div>
      </div>

      <div className='options'>
        {headerOptions.map((element, index) => {
          const onClick = () => setCurrentTab(element.option)
          return (
            <button
              key={`option-${index}`}
              className={currentTab === element.option ? 'selected' : ''}
              onClick={onClick}
            >
              {element.label}
            </button>
          )
        })}
      </div>
    </HeaderStyled>
  )
}

const HeaderStyled = styled.section`
  ${({ theme }) => theme.colors.grayShades[300]};

  .information {
    padding:1.5rem 2rem 1.5rem;;
    .path {
      height: 40px;
    }
  }

  .options {
    padding: 0 2rem;

    button {
      display: inline-flex;
      align-items: flex-end;
      position: relative;
      height: 20px;
      width: 140px;
      padding: 0;
      border: none;
      background-color: transparent;
      cursor: pointer;

      &.selected::after {
        content: '';
        height: 3px;
        width: 30px;

        position: absolute;
        bottom: -5px;
        left: 0;

        border-radius: 6px;
        background-color: ${({ theme }) =>
          theme.colors.grayShades[800].backgroundColor};
      }
    }
    button + button {
      margin-left: 20px;
    }
  }
`

export default Header
