import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import {
  CustomerDetailContext,
  CUSTOMER_DETAIL_SECTIONS
} from 'providers/customer-detail'
import { MenuBarContext } from 'providers/menu-bar'
import { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { validateStateRole } from 'utils/functions/role-manager'
import { CUSTOMER_STATES } from 'utils/schemas/customer'
import InfoSummary from './info-summary'

const ROUTER = {
  [CUSTOMER_DETAIL_SECTIONS.INFORMATION]: {
    label: 'Information'
  },
  [CUSTOMER_DETAIL_SECTIONS.DOCUMENTS]: {
    label: 'Documents'
  },
  [CUSTOMER_DETAIL_SECTIONS.COMMENTS]: {
    label: 'Comments'
  }
}

const DetailHeader = () => {
  const { currentSection, setCurrentSection, workflowStatus } = useContext(
    CustomerDetailContext
  )

  const { setAction } = useContext(MenuBarContext)

  const setCurrentTab = (action) => {
    if (currentSection !== action) {
      setCurrentSection(action)
    }
  }

  const onClickPendingActions = () => {
    setAction(true)
  }

  useEffect(() => {
    setCurrentSection(CUSTOMER_DETAIL_SECTIONS.INFORMATION)
  }, [setCurrentSection])

  return (
    <CustomerDetailHeaderStyled>
      <div className='actions btn-group'>
        {workflowStatus?.currentState &&
        CUSTOMER_STATES[workflowStatus?.currentState] &&
        validateStateRole(workflowStatus?.currentState) ? (
          <Button
            onClick={onClickPendingActions}
            id={CUSTOMER_STATES[workflowStatus?.currentState].actionLabel}
          >
            {CUSTOMER_STATES[workflowStatus?.currentState].actionLabel}
          </Button>
        ) : (
          !workflowStatus?.currentState && (
            <LoadingAnimation className='loading' />
          )
        )}
      </div>
      <InfoSummary currentRoute={[ROUTER[currentSection].label]} />
      <div className='customers-sections'>
        {Object.entries(ROUTER).map(([key, item], index) => {
          const { label } = item
          return (
            <button
              key={`section-${index}`}
              className={`${currentSection === key ? 'selected' : ''}`}
              onClick={() => setCurrentTab(key)}
            >
              {label}
            </button>
          )
        })}
      </div>
    </CustomerDetailHeaderStyled>
  )
}

const CustomerDetailHeaderStyled = styled.header`
  position: relative;
  padding: 2rem;
  padding-bottom: 0;

  ${({ theme }) => theme.colors.grayShades[300]};

  .clickable {
    cursor: pointer;
  }

  h3 {
    margin-top: 1rem;
  }

  .btn-group {
    position: absolute;
    right: 15px;
    button + button {
      margin-left: 15px;
    }
    .loading {
      width: 100px;
    }
  }

  .customers-sections {
    min-width: 800px;
    button {
      display: inline-flex;
      align-items: flex-end;
      position: relative;

      height: 50px;
      width: 150px;

      padding: 0;
      border: none;
      background-color: transparent;
      cursor: pointer;

      &.selected::after {
        content: '';
        height: 3px;
        width: 20%;

        position: absolute;
        bottom: -5px;
        left: 0;

        border-radius: 6px;
        background-color: ${({ theme }) =>
          theme.colors.grayShades[800].backgroundColor};
      }
    }
  }
`

export default DetailHeader
